import { Box, IconButton, HStack, useClipboard, useToast, Icon, Button } from "@chakra-ui/react"
import { FaTwitter, FaLinkedin, FaCopy, FaShare } from "react-icons/fa"
import { trackSocialInteraction, trackErrorEvent } from "../utils/analytics"

const SocialShare = ({ url, title, description }) => {
  const { hasCopied, onCopy } = useClipboard(url)
  const toast = useToast()

  // Handle native sharing (mobile devices)
  const handleNativeShare = async () => {
    try {
      await navigator.share({
        title,
        text: description,
        url
      });
      trackSocialInteraction("native", "share", url);
    } catch (error) {
      console.error("Error sharing:", error);
      trackErrorEvent("share_error", error.message);
    }
  };

  // Handle social platform sharing
  const handleShare = (platform) => {
    trackEvent("social_share", "click", platform);
    
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}&via=sigai10`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&source=sig-ai-inc`
    };

    window.open(shareUrls[platform], "_blank", "width=600,height=400");
  };

  // Handle link copying
  const handleCopy = () => {
    onCopy();
    trackEvent("social_share", "success", "copy");
    toast({
      title: "Link copied!",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box>
      <HStack spacing={2}>
        {/* Native Share Button (mobile) */}
        {navigator.share && (
          <Button
            leftIcon={<Icon as={FaShare} />}
            onClick={handleNativeShare}
            colorScheme="blue"
          >
            Share
          </Button>
        )}

        {/* Platform-specific share buttons */}
        {!navigator.share && (
          <>
            <IconButton
              aria-label="Share on Twitter"
              icon={<FaTwitter />}
              onClick={() => handleShare("twitter")}
              colorScheme="twitter"
              variant="ghost"
            />
            <IconButton
              aria-label="Share on LinkedIn"
              icon={<FaLinkedin />}
              onClick={() => handleShare("linkedin")}
              colorScheme="linkedin"
              variant="ghost"
            />
          </>
        )}

        {/* Copy Link Button */}
        <IconButton
          aria-label="Copy link"
          icon={<FaCopy />}
          onClick={handleCopy}
          colorScheme="gray"
          variant={hasCopied ? "solid" : "ghost"}
        />
      </HStack>
    </Box>
  );
};

export default SocialShare;
