import { Box, Container, Heading, Text, Button, SimpleGrid, VStack, Icon, Stack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FaBrain, FaChartLine, FaDatabase, FaUserShield } from 'react-icons/fa'

const Home = () => {
  const features = [
    {
      icon: FaBrain,
      title: 'Autonomous Marketing AI',
      description: 'Our multi-agent AI system continuously analyzes market signals, audience behavior, and competitive dynamics to optimize your marketing in real-time, delivering up to 40% improvement in ROAS.',
      link: '/platform#ai-agents'
    },
    {
      icon: FaChartLine,
      title: 'True Incremental Value',
      description: 'Move beyond misleading attribution models. Our incrementality testing framework measures the true causal impact of each channel and campaign, helping clients achieve 25-35% better budget efficiency.',
      link: '/solutions#marketing-roi'
    },
    {
      icon: FaUserShield,
      title: 'Strategic Control & Safety',
      description: 'Maintain full control with our human-in-the-loop approach. Set strategic guardrails, ensure brand safety, and let AI handle the complex optimization while your team focuses on high-level strategy.',
      link: '/platform#control'
    },
    {
      icon: FaDatabase,
      title: 'Future-Proof Analytics',
      description: 'Stay ahead of privacy changes with our cookie-less measurement system. Our probabilistic methods and first-party data approach ensure accurate attribution even in a post-cookie world.',
      link: '/solutions#attribution'
    }
  ]

  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" className="hero">
        <Container maxW="8xl" centerContent>
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading
              as="h1"
              fontSize={["var(--heading-size-lg)", "var(--heading-size-xl)"]}
              mb={6}
              bgGradient="linear(to-r, blue.600, purple.600)"
              bgClip="text"
              className="hero-title"
            >
              Maximize Marketing ROI with AI-Driven Incrementality
            </Heading>
            <Text 
              fontSize={["var(--text-size-base)", "var(--text-size-lg)"]} 
              color="gray.600" 
              mb={8}
              className="hero-text"
            >
              Stop wasting budget on ineffective channels. Our AI platform measures true incremental impact 
              and automatically optimizes your marketing mix in real-time.
            </Text>
            <Text
              fontSize={["var(--text-size-base)", "var(--text-size-lg)"]}
              color="blue.600"
              fontWeight="bold"
              mb={8}
              className="hero-stats"
            >
              Clients see 40% ROAS improvement and 30% better budget efficiency
            </Text>
            <Box height="1px" bg="gray.200" width="80px" mx="auto" mb={8} opacity={0.6} />
            <Stack 
              direction={{ base: 'column', sm: 'row' }} 
              spacing={4} 
              justify="center"
              className="hero-buttons"
            >
              <Button
                as={RouterLink}
                to="/contact"
                size={["md", "lg"]}
                colorScheme="blue"
                px={[6, 8]}
                className="cta-button"
              >
                Request a Demo
              </Button>
              <Button
                as={RouterLink}
                to="/platform"
                size={["md", "lg"]}
                variant="outline"
                px={[6, 8]}
                className="secondary-button"
              >
                Learn How We Do It
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box>

      {/* Features Section */}
      <Container maxW="8xl" py={20} centerContent>
        <Heading as="h2" size="xl" mb={10} textAlign="center">
          Key Features of Our AI Platform
        </Heading>
        <SimpleGrid 
          columns={{ base: 1, md: 2 }} 
          spacing={["var(--spacing-base)", "var(--spacing-base) * 2"]}
          className="feature-grid"
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              as={RouterLink}
              to={feature.link}
              p={[6, 8]}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ 
                transform: 'translateY(-4px)', 
                shadow: 'lg',
                textDecoration: 'none',
                '.feature-icon': { transform: 'scale(1.1)' },
                '.feature-title': { color: 'blue.600' }
              }}
              transition="all 0.3s"
              className="feature-card"
            >
              <VStack align="start" spacing={4}>
                <Icon 
                  as={feature.icon} 
                  w={[6, 8]} 
                  h={[6, 8]} 
                  color="blue.500"
                  transition="transform 0.3s"
                  className="feature-icon" 
                />
                <Heading 
                  as="h3" 
                  fontSize={["var(--heading-size-md)", "var(--heading-size-lg)"]}
                  transition="color 0.3s"
                  className="feature-title"
                >
                  {feature.title}
                </Heading>
                <Text 
                  color="gray.600"
                  fontSize={["var(--text-size-base)", "var(--text-size-lg)"]}
                  className="feature-description"
                >
                  {feature.description}
                </Text>
                <Text
                  color="blue.500"
                  fontSize="sm"
                  fontWeight="medium"
                  className="feature-link"
                >
                  Learn More →
                </Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>

      {/* CTA Section */}
      <Box bg="blue.600" py={16}>
        <Container maxW="8xl" centerContent>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
            <VStack align="start" spacing={6}>
              <Heading as="h2" size="xl" color="white">
                Ready to Maximize Your Marketing ROI?
              </Heading>
              <Text fontSize="lg" color="white" opacity={0.9}>
                Join leading brands using sig.ai to optimize their marketing spend and drive measurable growth.
                Schedule a demo to see our platform in action.
              </Text>
              <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
                <Button
                  as={RouterLink}
                  to="/contact"
                  size="lg"
                  colorScheme="white"
                  variant="solid"
                  color="blue.600"
                >
                  Schedule a Demo
                </Button>
                <Button
                  as={RouterLink}
                  to="/resources"
                  size="lg"
                  variant="outline"
                  color="white"
                  _hover={{ bg: 'blue.500' }}
                >
                  Download Resources
                </Button>
              </Stack>
            </VStack>
            <SimpleGrid columns={2} spacing={6}>
              <VStack>
                <Heading as="h3" size="3xl" color="white">40%</Heading>
                <Text color="white" textAlign="center">Average ROAS Improvement</Text>
              </VStack>
              <VStack>
                <Heading as="h3" size="3xl" color="white">30%</Heading>
                <Text color="white" textAlign="center">Better Budget Efficiency</Text>
              </VStack>
              <VStack>
                <Heading as="h3" size="3xl" color="white">2.5x</Heading>
                <Text color="white" textAlign="center">More Accurate Attribution</Text>
              </VStack>
              <VStack>
                <Heading as="h3" size="3xl" color="white">24/7</Heading>
                <Text color="white" textAlign="center">Real-time Optimization</Text>
              </VStack>
            </SimpleGrid>
          </SimpleGrid>
        </Container>
      </Box>

      {/* Case Studies Section */}
      <Box bg="gray.50" py={16}>
        <Container maxW="8xl" centerContent>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            {/* Featured Case Study */}
            <Box>
              <VStack align="start" spacing={6}>
                <Heading as="h2" size="lg">Success Stories</Heading>
                <Box bg="white" p={6} rounded="xl" shadow="md" w="full">
                  <VStack align="start" spacing={4}>
                    <Text fontSize="lg" fontWeight="bold" color="blue.600">
                      Leading Insurance Brokerage
                    </Text>
                    <Text fontSize="md" color="gray.600">
                      Achieved remarkable results with our AI-driven approach:
                    </Text>
                    <SimpleGrid columns={2} spacing={4} w="full">
                      <Box>
                        <Text fontSize="2xl" fontWeight="bold" color="blue.500">45%</Text>
                        <Text fontSize="sm" color="gray.600">Increase in ROAS</Text>
                      </Box>
                      <Box>
                        <Text fontSize="2xl" fontWeight="bold" color="blue.500">20%</Text>
                        <Text fontSize="sm" color="gray.600">Reduction in CAC</Text>
                      </Box>
                      <Box>
                        <Text fontSize="2xl" fontWeight="bold" color="blue.500">3.2x</Text>
                        <Text fontSize="sm" color="gray.600">LTV Improvement</Text>
                      </Box>
                      <Box>
                        <Text fontSize="2xl" fontWeight="bold" color="blue.500">60%</Text>
                        <Text fontSize="sm" color="gray.600">Better Attribution</Text>
                      </Box>
                    </SimpleGrid>
                  </VStack>
                </Box>
                <Button
                  as={RouterLink}
                  to="/about#case-studies"
                  variant="outline"
                  colorScheme="blue"
                  size="lg"
                  alignSelf="start"
                >
                  View All Case Studies
                </Button>
              </VStack>
            </Box>

            {/* Testimonial */}
            <Box>
              <VStack align="start" spacing={6}>
                <Heading as="h2" size="lg">Client Testimonial</Heading>
                <Box bg="white" p={6} rounded="xl" shadow="md">
                  <VStack align="start" spacing={4}>
                    <Text fontSize="lg" fontStyle="italic" color="gray.600">
                      "sig.ai's platform has transformed how we approach marketing attribution and optimization. 
                      The ability to measure true incremental impact and automatically adjust our strategy has 
                      been game-changing for our business."
                    </Text>
                    <Box>
                      <Text fontWeight="bold">Sarah Chen</Text>
                      <Text fontSize="sm" color="gray.600">VP of Marketing, Insurance Co</Text>
                    </Box>
                  </VStack>
                </Box>
              </VStack>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </Box>
  )
}

export default Home