import { Box, Container, Heading, Text, SimpleGrid, VStack, Icon } from '@chakra-ui/react'
import { FaStore, FaHospital, FaHome, FaGamepad } from 'react-icons/fa'

const Industries = () => {
  const industries = [
    {
      icon: FaStore,
      title: 'E-Commerce & Retail',
      challenge: 'Cookie deprecation, multi-SKU complexity, and inventory constraints.',
      solution: `Real-time budget reallocation, SKU-level performance tracking, and time-series 
        forecasting for demand optimization.`,
      result: `Major D2C brand saw 25% higher incremental conversions after implementing our 
        RL-based budget reallocation system.`
    },
    {
      icon: FaHospital,
      title: 'Insurance & Healthcare',
      challenge: 'Regulatory compliance, offline conversions, and privacy concerns.',
      solution: `Secure data pipelines, holdout-based incrementality measurement, and integrated 
        data from underwriting or claims systems.`,
      result: `Insurance brokerage improved lifetime value and reduced churn by 20% through 
        strategic budget allocation and ML models.`
    },
    {
      icon: FaHome,
      title: 'Real Estate & PropTech',
      challenge: 'Omnichannel leads, high-variance local markets, compliance nuances.',
      solution: `Dynamic, local-based ad optimizations that adapt to shifting real estate inventory 
        and demand in real time.`,
      result: `Real estate platform achieved 30% lift in qualified leads while reducing cost per 
        acquisition by 25%.`
    },
    {
      icon: FaGamepad,
      title: 'Gaming & Web3',
      challenge: 'Measuring ROAS in a rapidly changing user acquisition environment.',
      solution: `Real-time bidding optimizations, incrementality measurement across global markets, 
        AI for churn reduction.`,
      result: `Web3 gaming platform improved player retention by 35% while reducing acquisition 
        costs by 20%.`
    }
  ]

  return (
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>Industries</Heading>
            <Text fontSize="xl" color="gray.600">
              See how sig.ai delivers tailored solutions and measurable results across different industries.
            </Text>
          </Box>
        </Container>
      </Box>

      <Container maxW="8xl" py={16}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
          {industries.map((industry, index) => (
            <Box
              key={index}
              p={8}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
              transition="all 0.3s"
            >
              <VStack align="start" spacing={4}>
                <Icon as={industry.icon} w={8} h={8} color="blue.500" />
                <Heading size="md">{industry.title}</Heading>
                <Box>
                  <Text fontWeight="bold" color="gray.700">Challenge:</Text>
                  <Text color="gray.600">{industry.challenge}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.700">Our Solution:</Text>
                  <Text color="gray.600">{industry.solution}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.700">Results:</Text>
                  <Text color="gray.600">{industry.result}</Text>
                </Box>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default Industries