import { Box, Container, Heading, Text, Button, VStack, SimpleGrid, Icon, Link, HStack } from "@chakra-ui/react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { FaHome, FaSearch, FaBook, FaEnvelope, FaLinkedin, FaTwitter } from "react-icons/fa"
import { trackErrorEvent, trackButtonClick } from "../utils/analytics"
import React from "react"

const NotFound = () => {
  const location = useLocation();

  // Log 404 errors
  React.useEffect(() => {
    trackErrorEvent("404_error", `Page not found: ${location.pathname}`);
  }, [location]);

  const mainLinks = [
    { icon: FaHome, text: "Homepage", path: "/" },
    { icon: FaBook, text: "Resources", path: "/resources" },
    { icon: FaEnvelope, text: "Contact Us", path: "/contact" },
  ];

  const socialLinks = [
    { icon: FaLinkedin, text: "LinkedIn", url: "https://www.linkedin.com/company/sig-ai-inc" },
    { icon: FaTwitter, text: "Twitter", url: "https://twitter.com/sigai10" },
  ];

  const handleLinkClick = (linkName) => {
    trackButtonClick(linkName, "404_page");
  };

  return (
    <Box bg="gray.50" minH="calc(100vh - 200px)" display="flex" alignItems="center">
      <Container maxW="8xl" py={16}>
        <VStack spacing={12} align="center">
          {/* Main Message */}
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>Oops! Page Not Found</Heading>
            <Text fontSize="xl" color="gray.600" mb={8}>
              We could not find the page you are looking for. But do not worry,
              you can find lots of other great content using the links below.
            </Text>
          </Box>

          {/* Quick Links */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} width="100%" maxW="4xl">
            {mainLinks.map((link, index) => (
              <Button
                key={index}
                as={RouterLink}
                to={link.path}
                size="lg"
                height="auto"
                p={8}
                colorScheme="blue"
                variant={index === 0 ? "solid" : "outline"}
                onClick={() => handleLinkClick(link.text)}
              >
                <VStack spacing={4}>
                  <Icon as={link.icon} w={8} h={8} />
                  <Text>{link.text}</Text>
                </VStack>
              </Button>
            ))}
          </SimpleGrid>

          {/* Social Links */}
          <Box textAlign="center">
            <Text fontSize="lg" mb={4}>Follow us on social media:</Text>
            <HStack spacing={4} justify="center">
              {socialLinks.map((link, index) => (
                <Link
                  key={index}
                  href={link.url}
                  isExternal
                  onClick={() => handleLinkClick(`social_${link.text.toLowerCase()}`)}
                >
                  <Icon
                    as={link.icon}
                    w={6}
                    h={6}
                    color="gray.600"
                    _hover={{ color: "blue.500", transform: "scale(1.1)" }}
                    transition="all 0.2s"
                  />
                </Link>
              ))}
            </HStack>
          </Box>

          {/* Search Suggestion */}
          <Box textAlign="center" maxW="2xl">
            <Text color="gray.600">
              Looking for something specific? Try checking our{" "}
              <Link
                as={RouterLink}
                to="/resources"
                color="blue.500"
                fontWeight="medium"
                onClick={() => handleLinkClick("resources_link")}
              >
                resources page
              </Link>
              {" "}or{" "}
              <Link
                as={RouterLink}
                to="/contact"
                color="blue.500"
                fontWeight="medium"
                onClick={() => handleLinkClick("contact_link")}
              >
                contact us
              </Link>
              {" "}for help.
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default NotFound;
