import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem } from '@chakra-ui/react'

const TermsOfUse = () => {
  return (
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="4xl">
          <Heading as="h1" size="2xl" mb={6} textAlign="center">
            Terms of Use
          </Heading>
          <Text fontSize="lg" color="gray.600" textAlign="center">
            Effective Date: March 4, 2024
          </Text>
        </Container>
      </Box>

      <Container maxW="4xl" py={16}>
        <VStack spacing={12} align="start">
          <Text fontSize="lg" color="gray.700">
            Welcome to sig.ai. These Terms of Use govern your access to and use of the sig.ai website, services, products, and applications (collectively, "Services"). By using our Services, you agree to these Terms. If you do not agree, please do not use our Services.
          </Text>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Eligibility
            </Heading>
            <Text>
              You must be at least 13 years old to use our Services. By accessing or using our Services, you represent and warrant that you meet this age requirement.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              License to Use
            </Heading>
            <Text mb={4}>
              sig.ai grants you a limited, non-exclusive, non-transferable, and revocable license to access and use our Services solely for your personal, non-commercial use. This license does not permit:
            </Text>
            <UnorderedList spacing={2} pl={6}>
              <ListItem>Copying, modifying, or redistributing any content from our Services;</ListItem>
              <ListItem>Reverse engineering, decompiling, or otherwise attempting to extract source code;</ListItem>
              <ListItem>Engaging in automated data extraction or data mining;</ListItem>
              <ListItem>Using our Services for commercial purposes without our express written consent;</ListItem>
              <ListItem>Using our Services in any manner that violates applicable laws or regulations;</ListItem>
              <ListItem>Any activities that may damage, disable, or impair our Services.</ListItem>
            </UnorderedList>
            <Text mt={4}>
              sig.ai reserves the right to terminate your license at any time without notice for violations of these Terms.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Disclaimer of Warranties
            </Heading>
            <Text>
              Our Services are provided "as is" and "as available." sig.ai disclaims all warranties of any kind, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, and accuracy. sig.ai does not guarantee uninterrupted, secure, or error-free use of the Services or that defects will be corrected.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Limitation of Liability
            </Heading>
            <Text mb={4}>
              To the fullest extent permitted by applicable law, sig.ai, its affiliates, officers, directors, employees, agents, and suppliers shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or goodwill arising out of or related to your use of or inability to use our Services, regardless of whether we have been advised of the possibility of such damages.
            </Text>
            <Text>
              The total liability of sig.ai and its affiliates for any claims arising from or related to our Services is limited to the amount you paid us to use the Services or $100, whichever is greater.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Accuracy of Information
            </Heading>
            <Text>
              We strive to ensure accuracy and completeness, but we cannot guarantee that all information on our Services is accurate, complete, or up to date. Content may include technical, typographical, or photographic errors. sig.ai reserves the right to make changes at any time without prior notice.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Links to Third-Party Websites
            </Heading>
            <Text>
              Our Services may contain links to third-party websites or resources. sig.ai is not responsible for the content, accuracy, or availability of these third-party resources and does not endorse any linked websites or their content. You acknowledge that sig.ai is not liable for any damages or losses arising from your use of these third-party websites.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Changes to These Terms
            </Heading>
            <Text>
              sig.ai reserves the right to modify these Terms at any time. We will notify you of material changes by posting the updated Terms on our website. Your continued use of our Services after any such updates constitutes acceptance of the revised Terms.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Governing Law
            </Heading>
            <Text>
              These Terms are governed by and construed in accordance with the laws of the State of Washington, United States, without regard to conflict-of-law principles. You agree to submit to the exclusive jurisdiction and venue of the courts located in King County, Washington.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Indemnification
            </Heading>
            <Text>
              You agree to indemnify, defend, and hold harmless sig.ai, its affiliates, and their respective officers, directors, employees, and agents from and against all claims, liabilities, damages, losses, and expenses (including reasonable attorney's fees) arising from your use of the Services or violation of these Terms.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Severability
            </Heading>
            <Text>
              If any provision of these Terms is found unenforceable, the remaining provisions will continue to be valid and enforceable.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Entire Agreement
            </Heading>
            <Text>
              These Terms constitute the entire agreement between you and sig.ai concerning our Services and supersede any prior agreements.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Contact Information
            </Heading>
            <Text>
              For any questions or concerns about these Terms of Use, please contact us:
            </Text>
            <Text mt={4}>
              Email: legal@sig.ai
            </Text>
            <Text>
              Address: 7605 SE 27th St #102, Mercer Island, WA 98040, United States
            </Text>
          </Box>

          <Text fontSize="lg" fontStyle="italic" color="gray.600" mt={8}>
            Thank you for using sig.ai.
          </Text>
        </VStack>
      </Container>
    </Box>
  )
}

export default TermsOfUse