// Initialize data layer
window.dataLayer = window.dataLayer || [];

// Push event to data layer
export const pushEvent = (event) => {
  if (window.dataLayer) {
    window.dataLayer.push(event);
  }
};

// Track page view
export const trackPageView = (path) => {
  pushEvent({
    event: 'page_view',
    page: {
      path: path,
      title: document.title
    }
  });
};

// Track user interaction
export const trackInteraction = (category, action, label = null, value = null) => {
  const event = {
    event: 'user_interaction',
    interaction: {
      category: category,
      action: action
    }
  };

  if (label) {
    event.interaction.label = label;
  }

  if (value !== null) {
    event.interaction.value = value;
  }

  pushEvent(event);
};

// Track form submission
export const trackFormSubmission = (formName, success = true) => {
  pushEvent({
    event: 'form_submission',
    form: {
      name: formName,
      status: success ? 'success' : 'error'
    }
  });
};

// Track resource download
export const trackResourceDownload = (resourceName, resourceType) => {
  pushEvent({
    event: 'resource_download',
    resource: {
      name: resourceName,
      type: resourceType
    }
  });
};

// Track social share
export const trackSocialShare = (platform, contentType, contentId) => {
  pushEvent({
    event: 'social_share',
    social: {
      platform: platform,
      content_type: contentType,
      content_id: contentId
    }
  });
};

// Track error
export const trackError = (errorType, errorMessage, errorStack = null) => {
  const event = {
    event: 'error',
    error: {
      type: errorType,
      message: errorMessage
    }
  };

  if (errorStack) {
    event.error.stack = errorStack;
  }

  pushEvent(event);
};

// Track performance metrics
export const trackPerformance = (metric) => {
  pushEvent({
    event: 'performance',
    performance: {
      name: metric.name,
      value: Math.round(metric.value),
      rating: metric.rating // 'good', 'needs-improvement', or 'poor'
    }
  });
};

// Track user preferences
export const trackPreference = (preferenceName, preferenceValue) => {
  pushEvent({
    event: 'user_preference',
    preference: {
      name: preferenceName,
      value: preferenceValue
    }
  });
};

// Track feature usage
export const trackFeatureUsage = (featureName, action) => {
  pushEvent({
    event: 'feature_usage',
    feature: {
      name: featureName,
      action: action
    }
  });
};

// Track conversion
export const trackConversion = (type, value = null) => {
  const event = {
    event: 'conversion',
    conversion: {
      type: type
    }
  };

  if (value !== null) {
    event.conversion.value = value;
  }

  pushEvent(event);
};