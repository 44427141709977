import React from 'react'
import { createRoot } from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import App from './App'

console.log('Starting React initialization...')

try {
  const container = document.getElementById('root')
  if (!container) {
    throw new Error('Root element not found')
  }

  const root = createRoot(container)
  
  root.render(
    <ChakraProvider>
      <App />
    </ChakraProvider>
  )

  console.log('React app rendered successfully')
} catch (error) {
  console.error('Failed to initialize React:', error)
  
  // Show error to user
  const container = document.getElementById('root')
  if (container) {
    container.innerHTML = `
      <div style="color: red; padding: 20px; text-align: center;">
        <h1>Error Loading Application</h1>
        <p>${error.message}</p>
        <button onclick="location.reload()">Reload Page</button>
      </div>
    `
  }
}
