import {
  trackPageView as gtmTrackPageView,
  trackInteraction,
  trackFormSubmission as gtmTrackFormSubmission,
  trackResourceDownload as gtmTrackResourceDownload,
  trackSocialShare,
  trackError,
  trackPerformance,
  trackFeatureUsage,
  trackConversion
} from './gtm';

// Analytics event categories
export const EventCategory = {
  ENGAGEMENT: 'engagement',
  CONVERSION: 'conversion',
  RESOURCE: 'resource',
  NAVIGATION: 'navigation',
  SOCIAL: 'social',
  ERROR: 'error',
  PERFORMANCE: 'performance'
};

// Analytics event actions
export const EventAction = {
  CLICK: 'click',
  DOWNLOAD: 'download',
  FORM_SUBMIT: 'form_submit',
  VIEW: 'view',
  SHARE: 'share',
  ERROR: 'error',
  MEASURE: 'measure'
};

// Track page views
export const trackPageView = (path) => {
  gtmTrackPageView(path);
};

// Track resource downloads
export const trackResourceDownload = (resourceName, resourceType) => {
  gtmTrackResourceDownload(resourceName, resourceType);
  trackInteraction(EventCategory.RESOURCE, EventAction.DOWNLOAD, resourceName);
};

// Track form submissions
export const trackFormSubmission = (formName, success = true) => {
  gtmTrackFormSubmission(formName, success);
  if (success) {
    trackConversion('form_completion', formName);
  }
};

// Track button clicks
export const trackButtonClick = (buttonName, section) => {
  trackInteraction(EventCategory.ENGAGEMENT, EventAction.CLICK, `${section}: ${buttonName}`);
};

// Track section views
export const trackSectionView = (sectionName) => {
  trackInteraction(EventCategory.NAVIGATION, EventAction.VIEW, sectionName);
};

// Track social interactions
export const trackSocialInteraction = (platform, action, contentId) => {
  trackSocialShare(platform, action, contentId);
  trackInteraction(EventCategory.SOCIAL, EventAction.SHARE, platform);
};

// Track errors
export const trackErrorEvent = (errorType, errorMessage, errorStack = null) => {
  trackError(errorType, errorMessage, errorStack);
  trackInteraction(EventCategory.ERROR, EventAction.ERROR, errorType);
};

// Track performance
export const trackPerformanceMetric = (metricName, value, rating) => {
  trackPerformance({
    name: metricName,
    value: value,
    rating: rating
  });
  trackInteraction(EventCategory.PERFORMANCE, EventAction.MEASURE, metricName, value);
};

// Track feature usage
export const trackFeature = (featureName, action) => {
  trackFeatureUsage(featureName, action);
  trackInteraction(EventCategory.ENGAGEMENT, action, featureName);
};
