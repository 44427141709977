import { Box, Container, Heading, Text, SimpleGrid, VStack, Icon, Circle, Flex } from '@chakra-ui/react'
import { 
  FaDatabase, FaRobot, FaBrain, FaUsers, 
  FaCloudDownloadAlt, FaClock, FaNetworkWired,
  FaMobile, FaSearch, FaChartLine,
  FaLaptop, FaThumbsUp, FaCheckSquare, 
  FaChartBar, FaMicrochip, FaBolt,
  FaStar, FaGraduationCap
} from 'react-icons/fa'
import { BsGraphUp } from 'react-icons/bs'

const Platform = () => {
  const sections = [
    {
      icon: FaDatabase,
      title: 'Data & Engineering',
      headline: 'Clean, Connected, and Scalable Data Infrastructure.',
      description: `At sig.ai, our first step is ensuring your data pipeline is robust, unified, and future-proof. 
        We integrate seamlessly with every critical data point—ad platforms (Google, Meta, DV360), organic analytics, 
        offline events (POS, call centers), and even advanced signals like weather or competitor data.`
    },
    {
      icon: FaRobot,
      title: 'AI Agentic Flow',
      headline: "Multiple AI 'Agents' Working in Harmony.",
      description: `sig.ai employs a multi-agent reinforcement learning (MARL) architecture—a cutting-edge approach 
        where each 'agent' specializes in a core marketing function (budget allocation, bidding, creative testing). 
        These agents coordinate and communicate under a central coordinator to ensure consistent business outcomes.`
    },
    {
      icon: FaBrain,
      title: 'Reinforcement Learning',
      headline: 'Continuous Optimization, Powered by RL.',
      description: `Traditional rule-based systems can't keep up with complex, shifting markets. Our reinforcement 
        learning core leverages a continuous feedback loop: Observe, Decide, Reward, Learn. Results? Tangible, 
        incremental growth that outpaces conventional automation or manual campaign setups.`
    },
    {
      icon: FaUsers,
      title: 'Human-in-the-Loop',
      headline: 'Best of Both Worlds: Automation + Expert Oversight.',
      description: `While we specialize in advanced AI, we never leave the human touch behind. A dedicated marketing 
        strategist or data scientist can set guardrails, approve key changes, and review insights. This synergy ensures 
        you get the efficiency of AI plus the judgment and context only humans can provide.`
    }
  ]

  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Heading
            as="h1"
            size="2xl"
            mb={6}
            textAlign="center"
          >
            Our Platform
          </Heading>
          <Text
            fontSize="xl"
            color="gray.600"
            maxW="3xl"
            mx="auto"
            textAlign="center"
          >
            Discover how our cutting-edge platform combines data engineering, multi-agent AI, 
            and human expertise to deliver unprecedented marketing results.
          </Text>
        </Container>
      </Box>

      {/* Platform Sections */}
      <Container maxW="8xl" py={16}>
        <VStack spacing={16}>
          {sections.map((section, index) => (
            <Box 
              key={index} 
              w="full"
              id={section.title === 'AI Agentic Flow' ? 'ai-agents' : 
                 section.title === 'Human-in-the-Loop' ? 'control' : 
                 section.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}
              scrollMarginTop="100px"
            >
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                <VStack align="start" spacing={6}>
                  <Icon as={section.icon} w={12} h={12} color="blue.500" />
                  <Heading size="lg">{section.title}</Heading>
                  <Heading size="md" color="gray.700">{section.headline}</Heading>
                  <Text color="gray.600" fontSize="lg">
                    {section.description}
                  </Text>
                </VStack>
                {index === 0 || index === 1 || index === 2 ? (
                  <Box bg="blue.50" p={8} rounded="xl" position="relative">
                    {index === 0 ? (
                      <>
                        {/* Dataflow Section */}
                        <Text fontSize="xl" fontWeight="bold" mb={8}>Dataflow</Text>
                        <Flex align="center" mb={12}>
                          <Box p={3} border="1px" borderColor="blue.200" rounded="lg" bg="white">
                            <Text>Origin</Text>
                          </Box>
                          <Box flex="1" h="2px" bg="blue.200" mx={2} />
                          <Circle size="40px" bg="blue.200">
                            <Icon as={FaDatabase} color="white" />
                          </Circle>
                          <Box flex="1" h="2px" bg="blue.200" mx={2} />
                          <Box p={3} border="1px" borderColor="blue.200" rounded="lg" bg="white">
                            <Text>Destination</Text>
                          </Box>
                        </Flex>

                        {/* Storage Section */}
                        <Box mb={8}>
                          <Text fontSize="lg" fontWeight="bold" mb={4}>Storage</Text>
                          <Flex justify="space-between" mb={4}>
                            {[1, 2, 3, 4].map((i) => (
                              <Circle key={i} size="40px" bg="blue.200">
                                <Icon as={FaDatabase} color="white" />
                              </Circle>
                            ))}
                          </Flex>
                        </Box>

                        {/* Processing & Monitoring Grid */}
                        <SimpleGrid columns={2} spacing={8}>
                          <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={4}>Processing</Text>
                            <Flex justify="space-between">
                              <Box p={2} border="1px" borderColor="blue.200" rounded="lg">
                                <Icon as={FaCloudDownloadAlt} />
                              </Box>
                              <Box p={2} border="1px" borderColor="blue.200" rounded="lg">
                                <Icon as={FaClock} />
                              </Box>
                              <Box p={2} border="1px" borderColor="blue.200" rounded="lg">
                                <Icon as={FaNetworkWired} />
                              </Box>
                            </Flex>
                          </Box>
                          <Box>
                            <Text fontSize="lg" fontWeight="bold" mb={4}>Monitoring</Text>
                            <Flex justify="space-between">
                              <Box p={2} border="1px" borderColor="blue.200" rounded="lg">
                                <Icon as={FaMobile} />
                              </Box>
                              <Box p={2} border="1px" borderColor="blue.200" rounded="lg">
                                <Icon as={FaSearch} />
                              </Box>
                              <Box p={2} border="1px" borderColor="blue.200" rounded="lg">
                                <Icon as={FaChartLine} />
                              </Box>
                            </Flex>
                          </Box>
                        </SimpleGrid>
                      </>
                    ) : (
                      <>
                        {/* MultiAgent Pattern */}
                        <Box border="2px" borderColor="blue.200" rounded="xl" p={4} mb={6}>
                          <Text fontSize="xl" fontWeight="bold" color="blue.600" mb={4}>MultiAgent Pattern</Text>
                        </Box>
                        
                        <Box position="relative" h="300px">
                          {/* Central Agent */}
                          <Circle 
                            size="100px" 
                            bg="blue.100" 
                            border="2px"
                            borderColor="blue.400"
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                          >
                            <Icon as={FaRobot} boxSize={8} color="blue.600" />
                          </Circle>

                          {/* Surrounding Agents */}
                          {[
                            { top: "0%", left: "30%", label: "Budget Agent" },
                            { top: "30%", left: "80%", label: "Bidding Agent" },
                            { top: "70%", left: "20%", label: "Creative Agent" }
                          ].map((pos, i) => (
                            <Box key={i} position="absolute" top={pos.top} left={pos.left}>
                              <Circle 
                                size="80px" 
                                bg="purple.100"
                                border="2px"
                                borderColor="purple.400"
                              >
                                <Icon as={FaRobot} boxSize={6} color="purple.600" />
                              </Circle>
                              <Text 
                                fontSize="sm" 
                                fontWeight="bold" 
                                mt={2} 
                                textAlign="center"
                                color="gray.700"
                              >
                                {pos.label}
                              </Text>
                            </Box>
                          ))}

                          {/* Connection Lines */}
                          <Box 
                            position="absolute" 
                            top="0" 
                            left="0" 
                            right="0" 
                            bottom="0"
                            zIndex={-1}
                          >
                            <svg width="100%" height="100%" style={{ position: 'absolute' }}>
                              <defs>
                                <marker
                                  id="arrowhead"
                                  markerWidth="10"
                                  markerHeight="7"
                                  refX="9"
                                  refY="3.5"
                                  orient="auto"
                                >
                                  <polygon 
                                    points="0 0, 10 3.5, 0 7" 
                                    fill="#3182CE"
                                  />
                                </marker>
                              </defs>
                              <path
                                d="M150,50 L250,150 L150,250 L50,150 Z"
                                fill="none"
                                stroke="#3182CE"
                                strokeWidth="2"
                                strokeDasharray="5,5"
                                markerEnd="url(#arrowhead)"
                              />
                            </svg>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                ) : index === 1 ? (
                  <>
                    {/* MultiAgent Pattern */}
                    <Box border="2px" borderColor="blue.200" rounded="xl" p={4} mb={6}>
                      <Text fontSize="xl" fontWeight="bold" color="blue.600" mb={4}>MultiAgent Pattern</Text>
                    </Box>
                    
                    <Box position="relative" h="300px">
                      {/* Central Agent */}
                      <Circle 
                        size="100px" 
                        bg="blue.100" 
                        border="2px"
                        borderColor="blue.400"
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                      >
                        <Icon as={FaRobot} boxSize={8} color="blue.600" />
                      </Circle>

                      {/* Surrounding Agents */}
                      {[
                        { top: "0%", left: "30%", label: "Budget Agent" },
                        { top: "30%", left: "80%", label: "Bidding Agent" },
                        { top: "70%", left: "20%", label: "Creative Agent" }
                      ].map((pos, i) => (
                        <Box key={i} position="absolute" top={pos.top} left={pos.left}>
                          <Circle 
                            size="80px" 
                            bg="purple.100"
                            border="2px"
                            borderColor="purple.400"
                          >
                            <Icon as={FaRobot} boxSize={6} color="purple.600" />
                          </Circle>
                          <Text 
                            fontSize="sm" 
                            fontWeight="bold" 
                            mt={2} 
                            textAlign="center"
                            color="gray.700"
                          >
                            {pos.label}
                          </Text>
                        </Box>
                      ))}

                      {/* Connection Lines */}
                      <Box 
                        position="absolute" 
                        top="0" 
                        left="0" 
                        right="0" 
                        bottom="0"
                        zIndex={-1}
                      >
                        <svg width="100%" height="100%" style={{ position: 'absolute' }}>
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="10"
                              markerHeight="7"
                              refX="9"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon 
                                points="0 0, 10 3.5, 0 7" 
                                fill="#3182CE"
                              />
                            </marker>
                          </defs>
                          <path
                            d="M150,50 L250,150 L150,250 L50,150 Z"
                            fill="none"
                            stroke="#3182CE"
                            strokeWidth="2"
                            strokeDasharray="5,5"
                            markerEnd="url(#arrowhead)"
                          />
                        </svg>
                      </Box>
                    </Box>
                  </>
                ) : index === 2 ? (
                  <Box bg="blue.50" p={8} rounded="xl">
                    {/* Title */}
                    <Heading size="lg" mb={12} color="blue.700" textAlign="center">
                      Continuous Learning Cycle
                    </Heading>

                    {/* Main Cycle Diagram */}
                    <Box position="relative" h="400px">
                      {/* Center Brain */}
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        textAlign="center"
                      >
                        <Circle size="120px" bg="purple.500">
                          <Icon as={FaBrain} boxSize={12} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="purple.700" mt={4}>AI Model</Text>
                      </Box>

                      {/* Observe */}
                      <Box
                        position="absolute"
                        top="0"
                        left="50%"
                        transform="translateX(-50%)"
                        textAlign="center"
                      >
                        <Circle size="80px" bg="blue.500" mb={2}>
                          <Icon as={FaSearch} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="blue.700">Observe</Text>
                        <Text fontSize="sm" color="blue.600">Market Signals</Text>
                      </Box>

                      {/* Act */}
                      <Box
                        position="absolute"
                        right="0"
                        top="50%"
                        transform="translateY(-50%)"
                        textAlign="center"
                      >
                        <Circle size="80px" bg="green.500" mb={2}>
                          <Icon as={FaBolt} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="green.700">Act</Text>
                        <Text fontSize="sm" color="green.600">Optimize Decisions</Text>
                      </Box>

                      {/* Reward */}
                      <Box
                        position="absolute"
                        bottom="0"
                        left="50%"
                        transform="translateX(-50%)"
                        textAlign="center"
                      >
                        <Circle size="80px" bg="yellow.500" mb={2}>
                          <Icon as={FaStar} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="yellow.700">Reward</Text>
                        <Text fontSize="sm" color="yellow.600">Measure Impact</Text>
                      </Box>

                      {/* Learn */}
                      <Box
                        position="absolute"
                        left="0"
                        top="50%"
                        transform="translateY(-50%)"
                        textAlign="center"
                      >
                        <Circle size="80px" bg="red.500" mb={2}>
                          <Icon as={FaGraduationCap} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="red.700">Learn</Text>
                        <Text fontSize="sm" color="red.600">Update Strategy</Text>
                      </Box>

                      {/* Connection Arrows */}
                      <Box position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-1}>
                        <svg width="100%" height="100%">
                          <defs>
                            <marker
                              id="arrowhead-rl"
                              markerWidth="10"
                              markerHeight="7"
                              refX="9"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 10 3.5, 0 7" fill="#805AD5"/>
                            </marker>
                          </defs>
                          {/* Clockwise arrows */}
                          <path
                            d="M400,80 L520,200"
                            stroke="#805AD5"
                            strokeWidth="2"
                            strokeDasharray="5,5"
                            markerEnd="url(#arrowhead-rl)"
                          />
                          <path
                            d="M520,200 L400,320"
                            stroke="#805AD5"
                            strokeWidth="2"
                            strokeDasharray="5,5"
                            markerEnd="url(#arrowhead-rl)"
                          />
                          <path
                            d="M400,320 L280,200"
                            stroke="#805AD5"
                            strokeWidth="2"
                            strokeDasharray="5,5"
                            markerEnd="url(#arrowhead-rl)"
                          />
                          <path
                            d="M280,200 L400,80"
                            stroke="#805AD5"
                            strokeWidth="2"
                            strokeDasharray="5,5"
                            markerEnd="url(#arrowhead-rl)"
                          />
                        </svg>
                      </Box>

                      {/* Performance Metrics */}
                      <SimpleGrid 
                        columns={3} 
                        spacing={8}
                        position="absolute"
                        bottom="-80px"
                        left="50%"
                        transform="translateX(-50%)"
                        bg="white"
                        p={4}
                        rounded="xl"
                        shadow="md"
                        width="80%"
                      >
                        <Box textAlign="center">
                          <Text fontSize="2xl" fontWeight="bold" color="green.500">+40%</Text>
                          <Text fontSize="sm" color="gray.600">ROI Increase</Text>
                        </Box>
                        <Box textAlign="center">
                          <Text fontSize="2xl" fontWeight="bold" color="blue.500">-25%</Text>
                          <Text fontSize="sm" color="gray.600">Cost Reduction</Text>
                        </Box>
                        <Box textAlign="center">
                          <Text fontSize="2xl" fontWeight="bold" color="purple.500">2.5x</Text>
                          <Text fontSize="sm" color="gray.600">Faster Learning</Text>
                        </Box>
                      </SimpleGrid>
                    </Box>
                  </Box>
                ) : (
                  <Box bg="blue.50" p={8} rounded="xl">
                    {/* Title */}
                    <Heading size="lg" mb={12} color="blue.700" textAlign="center">
                      Responsible AI With Humans In The Loop
                    </Heading>

                    {/* Main Flow Diagram */}
                    <Box position="relative" h="400px">
                      {/* AI Model in Center */}
                      <Box
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -50%)"
                        textAlign="center"
                      >
                        <Circle size="100px" bg="blue.500" mb={2}>
                          <Icon as={FaMicrochip} boxSize={10} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="blue.700">AI Model</Text>
                      </Box>

                      {/* Human Validation Input */}
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        textAlign="center"
                        w="200px"
                      >
                        <Circle size="80px" bg="blue.500" mb={2} mx="auto">
                          <Icon as={FaLaptop} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="blue.700">
                          Validation of
                          <br />
                          Data by Human
                        </Text>
                      </Box>

                      {/* Dataset */}
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        textAlign="center"
                        w="200px"
                      >
                        <Circle size="80px" bg="blue.500" mb={2} mx="auto">
                          <Icon as={FaChartBar} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="blue.700">Dataset</Text>
                      </Box>

                      {/* System Output */}
                      <Box
                        position="absolute"
                        bottom="0"
                        right="0"
                        textAlign="center"
                        w="200px"
                      >
                        <Circle size="80px" bg="blue.500" mb={2} mx="auto">
                          <Icon as={FaCheckSquare} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="blue.700">System Output</Text>
                      </Box>

                      {/* Human Validation Output */}
                      <Box
                        position="absolute"
                        top="0"
                        right="0"
                        textAlign="center"
                        w="200px"
                      >
                        <Circle size="80px" bg="blue.500" mb={2} mx="auto">
                          <Icon as={FaThumbsUp} boxSize={8} color="white" />
                        </Circle>
                        <Text fontWeight="bold" color="blue.700">
                          Validation of Output
                          <br />
                          by Human
                        </Text>
                      </Box>

                      {/* Connection Arrows */}
                      <Box position="absolute" top="0" left="0" right="0" bottom="0" zIndex={-1}>
                        <svg width="100%" height="100%">
                          <defs>
                            <marker
                              id="arrowhead-blue"
                              markerWidth="10"
                              markerHeight="7"
                              refX="9"
                              refY="3.5"
                              orient="auto"
                            >
                              <polygon points="0 0, 10 3.5, 0 7" fill="#2B6CB0"/>
                            </marker>
                          </defs>
                          {/* Clockwise arrows */}
                          <path
                            d="M200,50 L400,200"
                            stroke="#2B6CB0"
                            strokeWidth="2"
                            markerEnd="url(#arrowhead-blue)"
                          />
                          <path
                            d="M400,200 L600,50"
                            stroke="#2B6CB0"
                            strokeWidth="2"
                            markerEnd="url(#arrowhead-blue)"
                          />
                          <path
                            d="M600,350 L400,200"
                            stroke="#2B6CB0"
                            strokeWidth="2"
                            markerEnd="url(#arrowhead-blue)"
                          />
                          <path
                            d="M400,200 L200,350"
                            stroke="#2B6CB0"
                            strokeWidth="2"
                            markerEnd="url(#arrowhead-blue)"
                          />
                          {/* Labels */}
                          <text x="250" y="100" fill="#2B6CB0" transform="rotate(-30, 250, 100)">
                            Optimized Data
                          </text>
                          <text x="500" y="100" fill="#2B6CB0" transform="rotate(30, 500, 100)">
                            Human Correction
                          </text>
                        </svg>
                      </Box>
                    </Box>
                  </Box>
                )}
              </SimpleGrid>
              {index < sections.length - 1 && <Box my={16} borderBottom="1px" borderColor="gray.200" />}
            </Box>
          ))}
        </VStack>
      </Container>
    </Box>
  )
}

export default Platform