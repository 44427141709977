import { Box, Container, Heading, Text, SimpleGrid, VStack, Avatar, Button, HStack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

const About = () => {
  const team = [
    {
      name: 'Yong Huang',
      role: 'Founder & CEO',
      bio: `Former Amazon AWS Analytics Lead and Redfin ML Director. Expert in data science and marketing analytics with 15+ years of experience building AI-driven solutions.`
    },
    {
      name: 'CJ',
      role: 'Principal Data Scientist',
      bio: `Ph.D. in Machine Learning and former Amazon Applied Scientist. Leading the development of advanced reinforcement learning systems for marketing optimization.`
    },
    {
      name: 'James Wang',
      role: 'Head of Engineering',
      bio: `Former Amazon Senior Engineer with extensive experience in distributed systems. Leading the development of scalable, real-time ML infrastructure and data pipelines.`
    }
  ]

  const caseStudies = [
    {
      title: 'E-Commerce Growth',
      description: '25% higher incremental conversions with a multi-agent budget optimizer.',
      industry: 'E-Commerce'
    },
    {
      title: 'Insurance Brokerage',
      description: '+20% LTV by predicting churn and optimizing channel mix.',
      industry: 'Insurance'
    },
    {
      title: 'Web3 Loyalty',
      description: 'Real-time, cross-channel marketing incrementality for 10M+ gamers.',
      industry: 'Gaming'
    }
  ]

  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" py={{ base: 12, md: 16 }}>
        <Container maxW={{ base: "95%", md: "90%", lg: "8xl" }} px={{ base: 4, md: 8 }}>
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>About Us</Heading>
            <Text fontSize={{ base: "lg", md: "xl" }} color="gray.600">
              We're on a mission to transform marketing through AI innovation, making it more 
              measurable, efficient, and impactful.
            </Text>
          </Box>
        </Container>
      </Box>

      {/* Vision Section */}
      <Container maxW={{ base: "95%", md: "90%", lg: "8xl" }} py={{ base: 12, md: 16 }} px={{ base: 4, md: 8 }}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 8, md: 10 }}>
          <Box>
            <Heading size="lg" mb={6}>Our Vision</Heading>
            <Text fontSize="lg" color="gray.600">
              sig.ai was founded with a mission to bridge the gap between advanced AI and 
              practical data-driven marketing. Our team of seasoned data scientists and 
              engineers brings deep industry expertise across e-commerce, healthcare, 
              insurance, and real estate.
            </Text>
          </Box>
          <Box>
            <Heading size="lg" mb={6}>Our Approach</Heading>
            <Text fontSize="lg" color="gray.600">
              We turn marketing into a precision engine—powered by reinforcement learning 
              and human intelligence—so businesses can adapt faster and achieve true, 
              incremental growth.
            </Text>
          </Box>
        </SimpleGrid>
      </Container>

      {/* Team Section */}
      <Box bg="gray.50" py={{ base: 12, md: 16 }}>
        <Container maxW={{ base: "95%", md: "90%", lg: "8xl" }} px={{ base: 4, md: 8 }}>
          <Heading size="xl" mb={{ base: 8, md: 12 }} textAlign="center">Our Team</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 8, md: 10 }}>
            {team.map((member, index) => (
              <VStack key={index} align="center" spacing={4}>
                <Avatar size="xl" name={member.name} />
                <Heading size="md">{member.name}</Heading>
                <Text fontWeight="medium" color="blue.600">{member.role}</Text>
                <Text color="gray.600" textAlign="center">{member.bio}</Text>
              </VStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Case Studies Section */}
      <Container maxW={{ base: "95%", md: "90%", lg: "8xl" }} py={{ base: 12, md: 16 }} px={{ base: 4, md: 8 }} id="case-studies">
        <Heading size="xl" mb={{ base: 8, md: 12 }} textAlign="center">Case Studies</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 8, md: 10 }}>
          {caseStudies.map((study, index) => (
            <Box
              key={index}
              p={{ base: 5, md: 6 }}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
              transition="all 0.3s"
            >
              <Text color="blue.600" fontWeight="medium" mb={2}>{study.industry}</Text>
              <Heading size="md" mb={4}>{study.title}</Heading>
              <Text color="gray.600">{study.description}</Text>
            </Box>
          ))}
        </SimpleGrid>
        <Box textAlign="center" mt={{ base: 8, md: 12 }}>
          <Button
            as={RouterLink}
            to="/contact"
            size="lg"
            colorScheme="blue"
          >
            Work With Us
          </Button>
        </Box>
      </Container>

      {/* Careers Section */}
      <Box bg="blue.50" py={{ base: 12, md: 16 }} id="careers">
        <Container maxW={{ base: "95%", md: "90%", lg: "8xl" }} px={{ base: 4, md: 8 }}>
          <Box maxW="3xl" mx="auto" textAlign="center" mb={{ base: 8, md: 12 }}>
            <Heading size="xl" mb={{ base: 4, md: 6 }}>Join Our Team</Heading>
            <Text fontSize={{ base: "md", md: "lg" }} color="gray.600">
              We're looking for passionate individuals who want to shape the future of AI-driven marketing.
              Join us in building cutting-edge solutions that drive real business impact.
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 6, md: 8 }}>
            {[
              {
                title: "Senior ML Engineer",
                type: "Full-time",
                location: "Remote",
                description: "Build and optimize multi-agent reinforcement learning systems for marketing automation."
              },
              {
                title: "Data Scientist",
                type: "Full-time",
                location: "Remote",
                description: "Develop and implement advanced analytics solutions for marketing attribution and optimization."
              }
            ].map((position, index) => (
              <Box
                key={index}
                bg="white"
                p={{ base: 5, md: 6 }}
                rounded="xl"
                shadow="base"
                _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
                transition="all 0.3s"
              >
                <Text color="blue.600" fontWeight="medium" mb={2}>{position.type} • {position.location}</Text>
                <Heading size="md" mb={4}>{position.title}</Heading>
                <Text color="gray.600" mb={{ base: 4, md: 6 }}>{position.description}</Text>
                <HStack spacing={4}>
                  <Button
                    as={RouterLink}
                    to={`/careers/${position.title === "Senior ML Engineer" ? "senior-ml-engineer" : "data-scientist"}`}
                    colorScheme="blue"
                    variant="outline"
                    size={{ base: "md", md: "md" }}
                  >
                    View Position
                  </Button>
                  <Button
                    as={RouterLink}
                    to={`/careers/${position.title === "Senior ML Engineer" ? "senior-ml-engineer" : "data-scientist"}`}
                    colorScheme="blue"
                    size={{ base: "md", md: "md" }}
                  >
                    Apply Now
                  </Button>
                </HStack>
              </Box>
            ))}
          </SimpleGrid>

          <Box textAlign="center" mt={{ base: 8, md: 12 }}>
            <Text fontSize={{ base: "md", md: "lg" }} mb={{ base: 3, md: 4 }}>
              Don't see a position that matches your skills?
            </Text>
            <HStack spacing={{ base: 3, md: 4 }} justify="center">
              <Button
                as={RouterLink}
                to="/careers/open-application"
                size={{ base: "md", md: "lg" }}
                colorScheme="blue"
                variant="outline"
              >
                View Details
              </Button>
              <Button
                as={RouterLink}
                to="/careers/open-application"
                size={{ base: "md", md: "lg" }}
                colorScheme="blue"
              >
                Send Open Application
              </Button>
            </HStack>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default About