import { Box, Container, Heading, Text, Image, VStack, HStack, Tag, Avatar, Divider, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import SocialShare from '../../components/SocialShare'

const ThirdPartyCookies = () => {
  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" py={16}>
        <Container maxW="4xl">
          <VStack spacing={6} align="start">
            <Tag colorScheme="blue">Analytics</Tag>
            <Heading as="h1" size="2xl">
              The End of Third-Party Cookies: A New Era of Marketing Measurement
            </Heading>
            <HStack spacing={4}>
              <Avatar name="CJ" />
              <Box>
                <Text fontWeight="medium">CJ</Text>
                <Text color="gray.600">Principal Data Scientist</Text>
              </Box>
              <Text color="gray.500">•</Text>
              <Text color="gray.600">Feb 2025</Text>
              <Text color="gray.500">•</Text>
              <Text color="gray.600">8 min read</Text>
            </HStack>
            <Box mt={4}>
              <SocialShare 
                url={window.location.href}
                title="The End of Third-Party Cookies: A New Era of Marketing Measurement"
              />
            </Box>
          </VStack>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxW="4xl" py={16}>
        <VStack spacing={8} align="start">
          <Image
            src="https://images.unsplash.com/photo-1614064641938-3bbee52942c7?w=1200&auto=format&fit=crop&q=80"
            alt="Digital privacy concept"
            rounded="xl"
            w="full"
            h="400px"
            objectFit="cover"
          />

          <Text fontSize="xl" color="gray.700">
            As Google phases out third-party cookies, businesses must adapt their marketing measurement strategies. 
            This shift represents both a challenge and an opportunity for marketers to develop more accurate, 
            privacy-conscious approaches to attribution.
          </Text>

          <Divider />

          <VStack spacing={6} align="start">
            <Heading size="lg">The Challenge of Cookie Deprecation</Heading>
            <Text>
              Third-party cookies have been the backbone of digital marketing measurement for over two decades. 
              Their deprecation forces marketers to rethink how they track user journeys, attribute conversions, 
              and measure campaign effectiveness. At sig.ai, we've developed innovative solutions that not only 
              address these challenges but provide more accurate measurement capabilities.
            </Text>

            <Heading size="lg">First-Party Data: The New Gold Standard</Heading>
            <Text>
              First-party data, collected directly from your users with their consent, offers several advantages:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Higher accuracy and reliability</Text>
                <Text>• Better compliance with privacy regulations</Text>
                <Text>• Deeper understanding of customer behavior</Text>
                <Text>• More meaningful personalization opportunities</Text>
              </VStack>
            </Box>

            <Heading size="lg">Probabilistic Matching: Beyond Deterministic Attribution</Heading>
            <Text>
              Our advanced probabilistic matching algorithms use machine learning to identify patterns and 
              connect user touchpoints across devices and channels. This approach has shown remarkable results:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• 40% improvement in attribution accuracy</Text>
                <Text>• 60% better cross-device user journey tracking</Text>
                <Text>• 25% increase in identifiable conversions</Text>
              </VStack>
            </Box>

            <Heading size="lg">Case Study: E-commerce Success</Heading>
            <Text>
              A leading e-commerce client implemented our cookie-less measurement solution and achieved:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• 45% more accurate ROAS measurement</Text>
                <Text>• 30% better campaign optimization</Text>
                <Text>• 20% reduction in customer acquisition costs</Text>
              </VStack>
            </Box>

            <Heading size="lg">Implementation Best Practices</Heading>
            <Text>
              To successfully transition to cookie-less measurement:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>1. Audit your current measurement stack</Text>
                <Text>2. Implement server-side tracking</Text>
                <Text>3. Develop a first-party data strategy</Text>
                <Text>4. Use probabilistic matching techniques</Text>
                <Text>5. Test and validate new measurement approaches</Text>
              </VStack>
            </Box>

            <Heading size="lg">The Future of Marketing Measurement</Heading>
            <Text>
              The end of third-party cookies isn't just a technical challenge—it's an opportunity to build 
              better, more privacy-conscious measurement systems. By embracing first-party data and advanced 
              analytics, marketers can achieve more accurate attribution while respecting user privacy.
            </Text>

            <Box bg="blue.50" p={6} rounded="xl" w="full">
              <VStack spacing={4} align="start">
                <Heading size="md">Key Takeaways</Heading>
                <Text>• Third-party cookie deprecation requires new measurement approaches</Text>
                <Text>• First-party data provides more accurate and compliant tracking</Text>
                <Text>• Probabilistic matching offers superior cross-device attribution</Text>
                <Text>• Implementation requires careful planning and testing</Text>
                <Text>• The future of measurement is privacy-first and more accurate</Text>
              </VStack>
            </Box>

            <Divider my={8} />

            <Box bg="gray.50" p={6} rounded="xl" w="full">
              <VStack spacing={4} align="start">
                <Heading size="md" color="blue.600">Learn More</Heading>
                <Text>
                  For a comprehensive guide on implementing privacy-first marketing analytics,
                  including strategies, tools, and compliance guidelines, download our strategic guide:
                </Text>
                <Text fontWeight="bold" color="blue.600">
                  <Link as={RouterLink} to="/resources#whitepapers">
                    Privacy-First Marketing Analytics Playbook →
                  </Link>
                </Text>
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default ThirdPartyCookies