import { useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const ScrollToSection = () => {
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()

  const scrollToSection = useCallback((hash) => {
    // Remove the '#' from the hash
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    
    if (element) {
      // Wait for any layout shifts to settle
      requestAnimationFrame(() => {
        const navbarHeight = 80
        const padding = 20
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - navbarHeight - padding

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })

        // Double-check position after animation
        setTimeout(() => {
          const finalPosition = element.getBoundingClientRect().top
          if (Math.abs(finalPosition - navbarHeight - padding) > 10) {
            window.scrollTo({
              top: window.pageYOffset + finalPosition - navbarHeight - padding,
              behavior: 'smooth'
            })
          }
        }, 500)
      })
      return true
    }
    return false
  }, [])

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
      return;
    }

    // Try to scroll immediately
    if (scrollToSection(hash)) {
      return;
    }

    // If initial scroll failed, try again when content loads
    let attempts = 0;
    const maxAttempts = 10;
    const retryInterval = 100;

    const retryScroll = () => {
      if (cleanup || attempts >= maxAttempts) {
        if (!cleanup) {
          console.warn(`Section ${hash} not found after ${maxAttempts} attempts`);
          navigate(pathname, { replace: true });
        }
        return;
      }

      attempts++;
      if (scrollToSection(hash)) {
        return;
      }

      // Try again after a short delay
      const timer = setTimeout(retryScroll, retryInterval);
      return () => clearTimeout(timer);
    };

    // Start retry process
    retryScroll();

    let cleanup = false;
    return () => { cleanup = true; };
  }, [pathname, hash, scrollToSection, navigate])

  // Handle in-page anchor clicks
  useEffect(() => {
    const handleClick = (event) => {
      const link = event.target.closest('a[href^="#"]')
      if (link) {
        event.preventDefault()
        const hash = link.getAttribute('href')
        if (hash === '#') return

        if (scrollToSection(hash)) {
          window.history.pushState(null, '', hash)
        }
      }
    }

    document.addEventListener('click', handleClick)
    return () => document.removeEventListener('click', handleClick)
  }, [scrollToSection])

  return null
}

export default ScrollToSection