import { Box, Container, Heading, Text, Image, VStack, HStack, Tag, Avatar, Divider, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

const IncrementalityTesting = () => {
  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" py={16}>
        <Container maxW="4xl">
          <VStack spacing={6} align="start">
            <Tag colorScheme="blue">Strategy</Tag>
            <Heading as="h1" size="2xl">
              Incrementality Testing at Scale: Moving Beyond A/B Tests
            </Heading>
            <HStack spacing={4}>
              <Avatar name="James Wang" />
              <Box>
                <Text fontWeight="medium">James Wang</Text>
                <Text color="gray.600">Head of Engineering</Text>
              </Box>
              <Text color="gray.500">•</Text>
              <Text color="gray.600">Dec 2024</Text>
              <Text color="gray.500">•</Text>
              <Text color="gray.600">10 min read</Text>
            </HStack>
          </VStack>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxW="4xl" py={16}>
        <VStack spacing={8} align="start">
          <Image
            src="https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=1200&auto=format&fit=crop&q=80"
            alt="Data analysis visualization"
            rounded="xl"
            w="full"
            h="400px"
            objectFit="cover"
          />

          <Text fontSize="xl" color="gray.700">
            Traditional A/B testing has long been the standard for measuring marketing effectiveness. 
            However, modern incrementality testing offers more sophisticated approaches that better 
            capture true marketing impact in today's complex digital landscape.
          </Text>

          <Divider />

          <VStack spacing={6} align="start">
            <Heading size="lg">Limitations of Traditional A/B Testing</Heading>
            <Text>
              While A/B testing is valuable, it has several limitations in modern marketing:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Doesn't account for cross-channel effects</Text>
                <Text>• Limited ability to measure long-term impact</Text>
                <Text>• Struggles with multi-touch attribution</Text>
                <Text>• Can't capture complex user journeys</Text>
              </VStack>
            </Box>

            <Heading size="lg">Advanced Incrementality Methods</Heading>
            <Text>
              Modern incrementality testing employs several sophisticated techniques:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>1. Ghost Ads: Simulate ad impressions without showing actual ads</Text>
                <Text>2. PSA Tests: Use public service announcements as control groups</Text>
                <Text>3. Geo-Experiments: Test across different geographic regions</Text>
                <Text>4. Holdout Groups: Maintain clean control audiences</Text>
              </VStack>
            </Box>

            <Heading size="lg">Implementation Strategy</Heading>
            <Text>
              Successfully implementing incrementality testing requires:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Clear test design and hypothesis formation</Text>
                <Text>• Proper audience segmentation</Text>
                <Text>• Statistical significance calculations</Text>
                <Text>• Cross-channel measurement capabilities</Text>
                <Text>• Long-term impact tracking</Text>
              </VStack>
            </Box>

            <Heading size="lg">Case Studies</Heading>
            <Text>
              Our clients have achieved significant results:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>Insurance Client:</Text>
                <Text>• 35% more accurate attribution</Text>
                <Text>• 25% reduction in wasted ad spend</Text>
                <Text>• 40% improvement in targeting efficiency</Text>
                <Text>E-commerce Client:</Text>
                <Text>• 30% better budget allocation</Text>
                <Text>• 45% increase in ROAS</Text>
                <Text>• 20% higher customer lifetime value</Text>
              </VStack>
            </Box>

            <Heading size="lg">Best Practices</Heading>
            <Text>
              To maximize the value of incrementality testing:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>1. Start with clear business objectives</Text>
                <Text>2. Use appropriate control groups</Text>
                <Text>3. Account for seasonality and external factors</Text>
                <Text>4. Measure both short and long-term effects</Text>
                <Text>5. Integrate findings into automated optimization</Text>
              </VStack>
            </Box>

            <Heading size="lg">Future of Incrementality Testing</Heading>
            <Text>
              Emerging trends and capabilities include:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Machine learning-powered test design</Text>
                <Text>• Real-time incrementality measurement</Text>
                <Text>• Cross-device attribution integration</Text>
                <Text>• Privacy-preserving measurement techniques</Text>
              </VStack>
            </Box>

            <Box bg="blue.50" p={6} rounded="xl" w="full">
              <VStack spacing={4} align="start">
                <Heading size="md">Key Takeaways</Heading>
                <Text>• Modern incrementality testing surpasses traditional A/B tests</Text>
                <Text>• Multiple methodologies provide comprehensive insights</Text>
                <Text>• Implementation requires careful planning and execution</Text>
                <Text>• Real-world results show significant improvements</Text>
                <Text>• Future developments will enhance capabilities</Text>
              </VStack>
            </Box>

            <Divider my={8} />

            <Box bg="gray.50" p={6} rounded="xl" w="full">
              <VStack spacing={4} align="start">
                <Heading size="md" color="blue.600">Learn More</Heading>
                <Text>
                  For a detailed guide on implementing incrementality testing, including step-by-step instructions,
                  case studies, and best practices, download our comprehensive whitepaper:
                </Text>
                <Text fontWeight="bold" color="blue.600">
                  <Link as={RouterLink} to="/resources#whitepapers">
                    The Complete Guide to Marketing Incrementality Measurement →
                  </Link>
                </Text>
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default IncrementalityTesting