import { Box } from '@chakra-ui/react'
import './global.css'
import ErrorBoundary from './components/ErrorBoundary'
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import Platform from './pages/Platform'
import Solutions from './pages/Solutions'
import Industries from './pages/Industries'
import About from './pages/About'
import Resources from './pages/Resources'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import Careers from './pages/Careers'
import JobApplication from './pages/JobApplication'
import ThirdPartyCookies from './pages/blog/ThirdPartyCookies'
import MultiAgentRL from './pages/blog/MultiAgentRL'
import IncrementalityTesting from './pages/blog/IncrementalityTesting'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsOfUse from './pages/TermsOfUse'
import { useEffect } from 'react'
import ScrollToSection from './components/ScrollToSection'
import { trackPageView } from './utils/analytics'
import { updateCanonicalUrl, updateMetaTags } from './utils/seo'

// ScrollToTop component to scroll window to top on route change and update SEO metadata
function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0);
    
    // Track page view
    trackPageView(pathname);
    
    // Update canonical URL for the current route
    updateCanonicalUrl(pathname);
    
    // Update page title and meta description based on the current route
    const routeTitles = {
      '/': 'sig.ai - AI-Powered Marketing Optimization | Maximize ROI with Machine Learning',
      '/platform': 'sig.ai Platform - Multi-Agent AI for Marketing Optimization',
      '/solutions': 'sig.ai Solutions - Marketing ROI, Attribution & Budget Optimization',
      '/industries': 'sig.ai Industries - AI Marketing Solutions for Various Sectors',
      '/about': 'About sig.ai - Our Mission, Team & Case Studies',
      '/contact': 'Contact sig.ai - Request a Demo or Get in Touch',
      '/resources': 'sig.ai Resources - Insights, White Papers & Events',
      '/careers': 'Careers at sig.ai - Join Our Team',
    };
    
    const routeDescriptions = {
      '/': 'Maximize marketing ROI with sig.ai\'s AI-powered optimization platform. Our multi-agent reinforcement learning technology delivers 40% ROAS improvement and 30% better budget efficiency.',
      '/platform': 'Discover sig.ai\'s cutting-edge platform combining data engineering, multi-agent AI, and human expertise to deliver unprecedented marketing results.',
      '/solutions': 'Explore sig.ai\'s solutions for marketing ROI optimization, attribution modeling, and budget allocation to maximize your marketing effectiveness.',
      '/industries': 'Industry-specific AI marketing solutions from sig.ai tailored for insurance, e-commerce, financial services, and more.',
      '/about': 'Learn about sig.ai\'s mission, team, and success stories. See how our AI-driven approach transforms marketing for leading brands.',
      '/contact': 'Get in touch with sig.ai to request a demo, discuss your marketing challenges, or explore partnership opportunities.',
      '/resources': 'Access sig.ai\'s latest insights, guides, and events to stay ahead in AI-driven marketing optimization.',
      '/careers': 'Explore career opportunities at sig.ai and join our team of AI, marketing, and data science experts.',
    };
    
    const title = routeTitles[pathname] || 'sig.ai - AI-Powered Marketing Optimization';
    const description = routeDescriptions[pathname] || 'Maximize marketing ROI with sig.ai\'s AI-powered optimization platform.';
    
    updateMetaTags(pathname, title, description);
  }, [pathname]);
  
  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <ScrollToSection />
      <Box minH="100vh" display="flex" flexDirection="column">
        <ErrorBoundary>
          <Navbar />
          <Box flex="1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/platform" element={<Platform />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/industries" element={<Industries />} />
              <Route path="/about" element={<About />} />
              <Route path="/resources/*" element={<Resources />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog/the-end-of-third-party-cookies-a-new-era-of-marketing-measurement" element={<ThirdPartyCookies />} />
              <Route path="/blog/multi-agent-reinforcement-learning-the-future-of-marketing-automation" element={<MultiAgentRL />} />
              <Route path="/blog/incrementality-testing-at-scale-moving-beyond-a-b-tests" element={<IncrementalityTesting />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/careers/:positionId" element={<JobApplication />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfUse />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
          </Box>
          <Footer />
        </ErrorBoundary>
      </Box>
    </Router>
  );
}

export default App
