import { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  VStack,
  Heading,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage
} from '@chakra-ui/react'

const ResourceRegistrationWall = ({ isOpen, onClose, resourceTitle, resourceUrl }) => {
  const toast = useToast()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    jobTitle: '',
    interests: '',
    marketingConsent: false
  })
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    })
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      })
    }
  }

  const validateForm = () => {
    const newErrors = {}
    
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First name is required'
    }
    
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last name is required'
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)) {
      newErrors.email = 'Invalid email address'
    }
    
    if (formData.phone.trim() && !/^[\d\s()+\-\.]+$/.test(formData.phone)) {
      newErrors.phone = 'Invalid phone number format'
    }
    
    if (!formData.company.trim()) {
      newErrors.company = 'Company name is required'
    }
    
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (!validateForm()) {
      return
    }
    
    setIsSubmitting(true)
    
    try {
      // Here you would send the data to your backend/API
      // const response = await fetch('/api/resource-registrations', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ ...formData, resourceRequested: resourceTitle })
      // })
      
      // Simulate API call
      await new Promise(resolve => {
        const timer = setTimeout(resolve, 1000);
        return () => clearTimeout(timer);
      })
      
      // If successful, allow download and close modal
      toast({
        title: 'Registration successful',
        description: 'Your download will begin shortly.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      
      // Trigger the download
      const link = document.createElement('a')
      link.href = resourceUrl
      link.download = resourceTitle
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      
      onClose()
    } catch (error) {
      toast({
        title: 'Registration failed',
        description: 'There was an error processing your request. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Register to Download</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4} align="stretch">
            <Box>
              <Heading size="md">{resourceTitle}</Heading>
              <Text mt={2} color="gray.600">
                Please fill out the form below to access this resource.
              </Text>
            </Box>
            
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch">
                <FormControl isRequired isInvalid={errors.firstName}>
                  <FormLabel>First Name</FormLabel>
                  <Input 
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                </FormControl>
                
                <FormControl isRequired isInvalid={errors.lastName}>
                  <FormLabel>Last Name</FormLabel>
                  <Input 
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                </FormControl>
                
                <FormControl isRequired isInvalid={errors.email}>
                  <FormLabel>Business Email</FormLabel>
                  <Input 
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                
                <FormControl isInvalid={errors.phone}>
                  <FormLabel>Phone Number</FormLabel>
                  <Input 
                    name="phone"
                    type="tel"
                    placeholder="(415) 439-0007"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.phone}</FormErrorMessage>
                </FormControl>
                
                <FormControl isRequired isInvalid={errors.company}>
                  <FormLabel>Company</FormLabel>
                  <Input 
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.company}</FormErrorMessage>
                </FormControl>
                
                <FormControl>
                  <FormLabel>Job Title</FormLabel>
                  <Input 
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                  />
                </FormControl>
                
                <FormControl>
                  <FormLabel>What are you interested in?</FormLabel>
                  <Select 
                    name="interests"
                    value={formData.interests}
                    onChange={handleChange}
                    placeholder="Select an option"
                  >
                    <option value="marketing-roi">Marketing ROI</option>
                    <option value="attribution">Attribution</option>
                    <option value="budget-optimization">Budget Optimization</option>
                    <option value="incrementality-testing">Incrementality Testing</option>
                    <option value="other">Other</option>
                  </Select>
                </FormControl>
                
                <FormControl>
                  <Checkbox 
                    name="marketingConsent"
                    isChecked={formData.marketingConsent}
                    onChange={handleChange}
                  >
                    I agree to receive marketing communications from sig.ai
                  </Checkbox>
                </FormControl>
                
                <Button 
                  mt={4}
                  colorScheme="blue"
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                  width="full"
                >
                  Download Resource
                </Button>
              </VStack>
            </form>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ResourceRegistrationWall