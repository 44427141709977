import { Box, Flex, Button, Stack, IconButton, useDisclosure, VStack, CloseButton } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { HamburgerIcon } from '@chakra-ui/icons'

const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const location = useLocation()

  const NAV_ITEMS = [
    { label: 'Platform', href: '/platform' },
    { label: 'Solutions', href: '/solutions' },
    { label: 'Industries', href: '/industries' },
    { label: 'About Us', href: '/about' },
    { label: 'Resources', href: '/resources' },
    { label: 'Careers', href: '/careers' },
  ]

  return (
    <Box 
      as="nav" 
      bg="white" 
      boxShadow="sm" 
      position="sticky" 
      top="0" 
      zIndex={1000}
      width="100%"
    >
      <Flex
        maxW="8xl"
        width="100%"
        mx="auto"
        px={{ base: 4, md: 8 }}
        py={4}
        align="center"
        justify="space-between"
      >
        <Box as={Link} to="/" display="flex" alignItems="center">
          <Box 
            as="svg"
            width="220px"
            height="45px"
            viewBox="0 0 220 50"
            xmlns="http://www.w3.org/2000/svg"
            _hover={{ transform: 'scale(1.05)' }}
            transition="all 0.2s"
          >
            <path 
              d="M20 20 
                 C20 10, 30 5, 37.5 12.5 
                 C45 5, 55 10, 55 20 
                 C55 30, 42.5 40, 37.5 42.5 
                 C32.5 40, 20 30, 20 20 Z"
              fill="#c51c1c"
            />
            <path 
              d="M15 20 
                 L22.5 20 
                 L25 15 
                 L27.5 25 
                 L30 20 
                 L37.5 20 
                 L40 12.5
                 L42.5 22.5
                 L45 17.5
                 L55 17.5"
              fill="none" 
              stroke="#ffffff" 
              stroke-width="1.5"
            />
            <text 
              x="65" 
              y="34" 
              fill="#2B6CB0" 
              fontFamily="Arial, sans-serif"
              fontSize="36"
              fontWeight="bold"
              letterSpacing="0.5"
            >
              sig.ai
            </text>
          </Box>
        </Box>

        {/* Mobile Menu Button */}
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onToggle}
          icon={<HamburgerIcon w={5} h={5} />}
          variant="ghost"
          aria-label="Toggle Navigation"
        />

        {/* Desktop Navigation */}
        <Stack
          direction="row"
          display={{ base: 'none', md: 'flex' }}
          spacing={2}
          align="center"
        >
          {NAV_ITEMS.map((navItem) => (
            <Button
              key={navItem.label}
              as={Link}
              to={navItem.href}
              variant="ghost"
              fontWeight="medium"
              color={location.pathname === navItem.href ? 'blue.500' : 'gray.600'}
              _hover={{ bg: 'gray.50', color: 'blue.500' }}
              size="md"
            >
              {navItem.label}
            </Button>
          ))}
          <Button
            as={Link}
            to="/contact"
            colorScheme="blue"
            size="md"
            ml={2}
          >
            Request Demo
          </Button>
        </Stack>

        {/* Mobile Navigation */}
        {isOpen && (
          <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="white"
            p={4}
            zIndex={2000}
            display={{ base: 'block', md: 'none' }}
          >
            <Flex justify="flex-end" mb={8}>
              <CloseButton onClick={onClose} />
            </Flex>
            <VStack spacing={4} align="stretch">
              {NAV_ITEMS.map((navItem) => (
                <Button
                  key={navItem.label}
                  as={Link}
                  to={navItem.href}
                  variant="ghost"
                  fontWeight="medium"
                  color={location.pathname === navItem.href ? 'blue.500' : 'gray.600'}
                  _hover={{ bg: 'gray.50', color: 'blue.500' }}
                  size="lg"
                  justifyContent="flex-start"
                  onClick={onClose}
                >
                  {navItem.label}
                </Button>
              ))}
              <Button
                as={Link}
                to="/contact"
                colorScheme="blue"
                size="lg"
                onClick={onClose}
              >
                Request Demo
              </Button>
            </VStack>
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export default Navbar