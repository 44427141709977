import { Box, Container, Heading, Text, VStack, UnorderedList, ListItem } from '@chakra-ui/react'

const PrivacyPolicy = () => {
  return (
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="4xl">
          <Heading as="h1" size="2xl" mb={6} textAlign="center">
            Privacy Policy
          </Heading>
          <Text fontSize="lg" color="gray.600" textAlign="center">
            Effective Date: March 4, 2024
          </Text>
        </Container>
      </Box>

      <Container maxW="4xl" py={16}>
        <VStack spacing={8} align="start">
          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Introduction
            </Heading>
            <Text>
              sig.ai inc. ("we", "us", or "our"), a Washington State corporation, is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Information We Collect
            </Heading>
            <Text mb={4}>We collect information that you provide directly to us, including:</Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>Contact information (name, email, phone number)</ListItem>
              <ListItem>Company information</ListItem>
              <ListItem>Marketing preferences</ListItem>
              <ListItem>Service usage information</ListItem>
            </UnorderedList>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              How We Use Your Information
            </Heading>
            <Text mb={4}>We use the information we collect to:</Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>Provide and improve our services</ListItem>
              <ListItem>Communicate with you about our services</ListItem>
              <ListItem>Send marketing communications (with your consent)</ListItem>
              <ListItem>Analyze and improve our website performance</ListItem>
              <ListItem>Comply with legal obligations</ListItem>
            </UnorderedList>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Data Security
            </Heading>
            <Text>
              We implement appropriate technical and organizational security measures to protect your information. However, no electronic transmission or storage system is 100% secure, and we cannot guarantee absolute security.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Cookie Usage
            </Heading>
            <Text mb={4}>
              We use cookies and similar tracking technologies to enhance your browsing experience and analyze website traffic. These include:
            </Text>
            <UnorderedList spacing={2} pl={4} mb={4}>
              <ListItem>Essential cookies necessary for website functionality</ListItem>
              <ListItem>Analytics cookies to understand user behavior</ListItem>
              <ListItem>Marketing cookies for personalized content (with consent)</ListItem>
              <ListItem>Preference cookies to remember your settings</ListItem>
            </UnorderedList>
            <Text>
              You can control cookie preferences through your browser settings. Note that disabling certain cookies may impact website functionality.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Third-Party Services
            </Heading>
            <Text>
              Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Your Privacy Rights
            </Heading>
            <Text mb={4}>Under various privacy laws, you have the following rights:</Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>Access your personal information</ListItem>
              <ListItem>Correct inaccurate information</ListItem>
              <ListItem>Request deletion of your information</ListItem>
              <ListItem>Opt-out of marketing communications</ListItem>
              <ListItem>Withdraw consent where applicable</ListItem>
              <ListItem>Data portability (receive your data in a structured format)</ListItem>
              <ListItem>Object to processing of your information</ListItem>
              <ListItem>Restrict processing of your information</ListItem>
            </UnorderedList>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              GDPR Compliance
            </Heading>
            <Text mb={4}>
              For users in the European Economic Area (EEA), we comply with the General Data Protection Regulation (GDPR). This means:
            </Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>We process data lawfully, fairly, and transparently</ListItem>
              <ListItem>We collect data only for specified, explicit, and legitimate purposes</ListItem>
              <ListItem>We minimize data collection to what's necessary</ListItem>
              <ListItem>We ensure data accuracy and provide correction mechanisms</ListItem>
              <ListItem>We limit data retention to what's necessary</ListItem>
              <ListItem>We implement appropriate security measures</ListItem>
              <ListItem>We require explicit consent for processing sensitive data</ListItem>
              <ListItem>We honor data subject rights under GDPR</ListItem>
            </UnorderedList>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              CCPA Compliance
            </Heading>
            <Text mb={4}>
              For California residents, under the California Consumer Privacy Act (CCPA), you have additional rights:
            </Text>
            <UnorderedList spacing={2} pl={4}>
              <ListItem>Right to know what personal information we collect and how we use it</ListItem>
              <ListItem>Right to delete your personal information</ListItem>
              <ListItem>Right to opt-out of the sale of your personal information</ListItem>
              <ListItem>Right to non-discrimination for exercising your CCPA rights</ListItem>
            </UnorderedList>
            <Text mt={4}>
              To exercise your CCPA rights, you can contact us at privacy@sig.ai or call (415) 439-0007. We will respond to verifiable consumer requests within 45 days.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              International Data Transfers
            </Heading>
            <Text>
              We may transfer your information to countries outside your residence jurisdiction. When we do, we ensure appropriate safeguards are in place, including standard contractual clauses and other legal mechanisms to protect your data in accordance with applicable laws.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Changes to This Policy
            </Heading>
            <Text>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="lg" mb={4}>
              Contact Us
            </Heading>
            <Text>
              If you have any questions about this Privacy Policy, please contact us:
            </Text>
            <Text mt={4}>
              Email: privacy@sig.ai
            </Text>
            <Text>
              Address: 7605 SE 27th St #102, Mercer Island, WA 98040, United States
            </Text>
          </Box>
        </VStack>
      </Container>
    </Box>
  )
}

export default PrivacyPolicy