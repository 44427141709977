import { Box, Container, Heading, Text, Image, VStack, HStack, Tag, Avatar, Divider, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

const MultiAgentRL = () => {
  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" py={16}>
        <Container maxW="4xl">
          <VStack spacing={6} align="start">
            <Tag colorScheme="blue">AI & ML</Tag>
            <Heading as="h1" size="2xl">
              Multi-Agent Reinforcement Learning: The Future of Marketing Automation
            </Heading>
            <HStack spacing={4}>
              <Avatar name="Yong Huang" />
              <Box>
                <Text fontWeight="medium">Yong Huang</Text>
                <Text color="gray.600">Founder & CEO</Text>
              </Box>
              <Text color="gray.500">•</Text>
              <Text color="gray.600">Jan 2025</Text>
              <Text color="gray.500">•</Text>
              <Text color="gray.600">12 min read</Text>
            </HStack>
          </VStack>
        </Container>
      </Box>

      {/* Main Content */}
      <Container maxW="4xl" py={16}>
        <VStack spacing={8} align="start">
          <Image
            src="https://images.unsplash.com/photo-1620712943543-bcc4688e7485?w=1200&auto=format&fit=crop&q=80"
            alt="AI network visualization"
            rounded="xl"
            w="full"
            h="400px"
            objectFit="cover"
          />

          <Text fontSize="xl" color="gray.700">
            Multi-agent reinforcement learning (MARL) is revolutionizing marketing automation by enabling 
            coordinated decision-making across multiple AI agents, each specialized in different aspects 
            of marketing optimization.
          </Text>

          <Divider />

          <VStack spacing={6} align="start">
            <Heading size="lg">Understanding Multi-Agent Systems</Heading>
            <Text>
              In traditional marketing automation, single AI models handle specific tasks in isolation. 
              MARL takes a different approach by deploying multiple specialized agents that work together, 
              sharing information and coordinating decisions to achieve optimal outcomes.
            </Text>

            <Heading size="lg">Key Components of Our MARL System</Heading>
            <Text>
              Our platform consists of several specialized agents:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Budget Allocation Agent: Optimizes spending across channels</Text>
                <Text>• Bidding Agent: Manages real-time bid adjustments</Text>
                <Text>• Creative Selection Agent: Chooses optimal ad creatives</Text>
                <Text>• Audience Agent: Identifies and targets valuable segments</Text>
                <Text>• Coordinator Agent: Ensures coherent cross-agent decisions</Text>
              </VStack>
            </Box>

            <Heading size="lg">Agent Coordination Mechanisms</Heading>
            <Text>
              Effective coordination between agents is achieved through:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Shared state representations</Text>
                <Text>• Hierarchical reward structures</Text>
                <Text>• Message passing protocols</Text>
                <Text>• Joint action spaces</Text>
              </VStack>
            </Box>

            <Heading size="lg">Case Study: D2C Brand Success</Heading>
            <Text>
              A direct-to-consumer brand implemented our MARL system and achieved:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• 65% improvement in ROAS</Text>
                <Text>• 40% reduction in customer acquisition cost</Text>
                <Text>• 85% faster campaign optimization</Text>
                <Text>• 50% increase in conversion rates</Text>
              </VStack>
            </Box>

            <Heading size="lg">Technical Implementation</Heading>
            <Text>
              Key technical aspects of our MARL implementation:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>1. Distributed training architecture</Text>
                <Text>2. Custom reward shaping for marketing objectives</Text>
                <Text>3. Real-time state synchronization</Text>
                <Text>4. Robust exploration strategies</Text>
                <Text>5. Continuous learning and adaptation</Text>
              </VStack>
            </Box>

            <Heading size="lg">Future Developments</Heading>
            <Text>
              We're actively working on several exciting enhancements:
            </Text>
            <Box pl={6}>
              <VStack spacing={3} align="start">
                <Text>• Advanced natural language processing for creative generation</Text>
                <Text>• Cross-channel attribution modeling</Text>
                <Text>• Automated creative testing and optimization</Text>
                <Text>• Enhanced privacy-preserving learning methods</Text>
              </VStack>
            </Box>

            <Box bg="blue.50" p={6} rounded="xl" w="full">
              <VStack spacing={4} align="start">
                <Heading size="md">Key Takeaways</Heading>
                <Text>• MARL enables coordinated marketing automation</Text>
                <Text>• Specialized agents handle different marketing functions</Text>
                <Text>• Coordination mechanisms ensure coherent decisions</Text>
                <Text>• Real-world results show significant improvements</Text>
                <Text>• Future developments will further enhance capabilities</Text>
              </VStack>
            </Box>

            <Divider my={8} />

            <Box bg="gray.50" p={6} rounded="xl" w="full">
              <VStack spacing={4} align="start">
                <Heading size="md" color="blue.600">Learn More</Heading>
                <Text>
                  For a deep dive into our multi-agent reinforcement learning architecture, including technical details,
                  implementation guides, and performance analysis, download our technical whitepaper:
                </Text>
                <Text fontWeight="bold" color="blue.600">
                  <Link as={RouterLink} to="/resources#whitepapers">
                    Multi-Agent Reinforcement Learning for Marketing Optimization →
                  </Link>
                </Text>
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default MultiAgentRL