import { Box, Stack, Text, Link as ChakraLink, SimpleGrid } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"

const Footer = () => {
  return (
    <Box bg="gray.50" color="gray.700" mt="auto" width="100%">
      <Box
        maxW="8xl"
        width="100%"
        mx="auto"
        px={{ base: 4, md: 8 }}
        py={10}
      >
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8}>
          {/* Company Info */}
          <Stack spacing={6}>
            <ChakraLink 
              as={RouterLink} 
              to="/"
              display="inline-block"
              _hover={{ opacity: 0.8 }}
              transition="opacity 0.2s"
            >
              <Box 
                as="svg"
                width="220px"
                height="45px"
                viewBox="0 0 220 50"
                xmlns="http://www.w3.org/2000/svg"
                mb={2}
              >
                <path 
                  d="M20 20 
                     C20 10, 30 5, 37.5 12.5 
                     C45 5, 55 10, 55 20 
                     C55 30, 42.5 40, 37.5 42.5 
                     C32.5 40, 20 30, 20 20 Z"
                  fill="#c51c1c"
                />
                <path 
                  d="M15 20 
                     L22.5 20 
                     L25 15 
                     L27.5 25 
                     L30 20 
                     L37.5 20 
                     L40 12.5
                     L42.5 22.5
                     L45 17.5
                     L55 17.5"
                  fill="none" 
                  stroke="#ffffff" 
                  strokeWidth="1.5"
                />
                <text 
                  x="65" 
                  y="34" 
                  fill="#2B6CB0" 
                  fontFamily="Arial, sans-serif"
                  fontSize="36"
                  fontWeight="bold"
                  letterSpacing="0.5"
                >
                  sig.ai
                </text>
              </Box>
            </ChakraLink>
            <Text fontSize="sm" color="gray.600">
              Data-Driven, AI-First Marketing.
              <br />
              Turning Signals into Actions.
            </Text>
          </Stack>

          {/* Company Links */}
          <Stack spacing={4}>
            <Text fontWeight="semibold">Company</Text>
            <ChakraLink as={RouterLink} to="/about">About Us</ChakraLink>
            <ChakraLink as={RouterLink} to="/careers">Careers</ChakraLink>
            <ChakraLink as={RouterLink} to="/contact">Contact</ChakraLink>
          </Stack>

          {/* Solutions Links */}
          <Stack spacing={4}>
            <Text fontWeight="semibold">Solutions</Text>
            <ChakraLink as={RouterLink} to="/solutions#marketing-roi">Marketing ROI</ChakraLink>
            <ChakraLink as={RouterLink} to="/solutions#attribution">Attribution</ChakraLink>
            <ChakraLink as={RouterLink} to="/solutions#optimization">Budget Optimization</ChakraLink>
          </Stack>

          {/* Resources Links */}
          <Stack spacing={4}>
            <Text fontWeight="semibold">Resources</Text>
            <ChakraLink as={RouterLink} to="/resources">Blog</ChakraLink>
            <ChakraLink as={RouterLink} to="/resources#whitepapers">White Papers</ChakraLink>
            <ChakraLink as={RouterLink} to="/resources#events">Events</ChakraLink>
          </Stack>
        </SimpleGrid>

        <Box borderTopWidth={1} borderColor="gray.200" pt={8} mt={8}>
          <Stack 
            direction={{ base: 'column', md: 'row' }} 
            spacing={{ base: 2, md: 4 }} 
            justify="center" 
            align="center" 
            divider={<Text color="gray.600" mx={2} display={{ base: 'none', md: 'block' }}>•</Text>}
            fontSize="sm"
            color="gray.600"
            textAlign="center"
          >
            <ChakraLink as={RouterLink} to="/privacy">Privacy Policy</ChakraLink>
            <ChakraLink as={RouterLink} to="/terms">Terms of Use</ChakraLink>
            <Text>© {new Date().getFullYear()} sig.ai inc. All rights reserved.</Text>
            <Text>sig.ai inc. is headquartered in Washington State, United States</Text>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
