import { Box, Container, Heading, Text, SimpleGrid, VStack, Button, Icon, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Divider, Link } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import { FaEnvelope, FaCalendar, FaPhone, FaBuilding } from "react-icons/fa"
import { InlineWidget } from "react-calendly"

const Contact = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Calendly configuration
  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "3182ce", // Chakra UI blue.500
    textColor: "2d3748"    // Chakra UI gray.700
  }

  const contactMethods = [
    {
      icon: FaCalendar,
      title: "Schedule a Demo",
      description: "Book a personalized demo to see our platform in action.",
      action: "Schedule Now",
      primary: true,
      onClick: onOpen
    },
    {
      icon: FaEnvelope,
      title: "Email Us",
      description: "Send us a message at contact@sig.ai",
      action: "Send Email",
      href: "mailto:contact@sig.ai"
    },
    {
      icon: FaPhone,
      title: "Call Us",
      description: "Call us at (415) 439-0007",
      action: "Call Now",
      href: "tel:+14154390007"
    }
  ]

  return (
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>Ready to See True Incremental Lift?</Heading>
            <Text fontSize="xl" color="gray.600">
              Connect with our team to explore how sig.ai can transform your marketing ROI.
            </Text>
          </Box>
        </Container>
      </Box>

      <Container maxW="8xl" py={16}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {contactMethods.map((method, index) => (
            <Box
              key={index}
              p={8}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ transform: "translateY(-4px)", shadow: "lg" }}
              transition="all 0.3s"
            >
              <VStack spacing={6} align="center">
                <Icon as={method.icon} w={8} h={8} color="blue.500" />
                <Heading size="md">{method.title}</Heading>
                <Text color="gray.600" textAlign="center">
                  {method.description}
                </Text>
                <Button
                  as={method.href ? "a" : "button"}
                  href={method.href}
                  onClick={method.onClick}
                  colorScheme="blue"
                  variant={method.primary ? "solid" : "outline"}
                  size="lg"
                  width="full"
                >
                  {method.action}
                </Button>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>

        {/* Office Address */}
        <Box maxW="3xl" mx="auto" mt={20} textAlign="center">
          <VStack spacing={4} mb={12}>
            <Icon as={FaBuilding} w={8} h={8} color="blue.500" />
            <Heading size="md">Our Office</Heading>
            <Link 
              href="https://maps.google.com/?q=7605+SE+27th+St+%23102+Mercer+Island+WA+98040" 
              isExternal
              _hover={{ color: 'blue.500' }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="lg" mb={2}>
                7605 SE 27th St #102<br />
                Mercer Island, WA 98040
              </Text>
              <Text fontSize="sm" color="blue.500">
                Open in Google Maps
              </Text>
            </Link>
          </VStack>

          <Divider mb={12} />

          <Heading size="lg" mb={6}>Looking to Join Our Team?</Heading>
          <Text fontSize="lg" color="gray.600" mb={8}>
            We are always looking for talented individuals who are passionate about AI, 
            data science, and marketing technology.
          </Text>
          <Button
            as={RouterLink}
            to="/careers"
            colorScheme="blue"
            variant="outline"
            size="lg"
          >
            View Open Positions
          </Button>
        </Box>
      </Container>

      {/* Calendly Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent maxW="1000px" h="700px">
          <ModalHeader>Schedule a Demo</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <InlineWidget
              url="https://calendly.com/yonghuang1"
              styles={{
                height: "630px",
                width: "100%"
              }}
              pageSettings={pageSettings}
              prefill={{
                email: "",
                firstName: "",
                lastName: "",
                name: ""
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default Contact
