import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
  Alert,
  AlertIcon,
  FormHelperText,
  Select,
  HStack,
  Link
} from '@chakra-ui/react'
import { FaArrowLeft } from 'react-icons/fa'

const positions = {
  "senior-ml-engineer": {
    title: "Senior ML Engineer",
    type: "Full-time",
    location: "Remote",
    description: "Build and optimize multi-agent reinforcement learning systems for marketing automation.",
    requirements: [
      "Ph.D. or M.S. in Computer Science, Machine Learning, or related field",
      "5+ years of experience in ML engineering",
      "Deep understanding of RL algorithms (DQN, PPO, DDPG)",
      "Experience with multi-agent systems",
      "Proficiency in Python and ML frameworks",
      "Strong system design and architecture skills"
    ],
    responsibilities: [
      "Design and implement multi-agent reinforcement learning architectures",
      "Develop real-time bidding and budget optimization algorithms",
      "Create scalable ML pipelines for model training and deployment",
      "Optimize model performance and system efficiency",
      "Collaborate with data scientists to improve prediction accuracy",
      "Implement privacy-preserving ML techniques"
    ]
  },
  "data-scientist": {
    title: "Data Scientist",
    type: "Full-time",
    location: "Remote",
    description: "Develop and implement advanced analytics solutions for marketing attribution and optimization.",
    requirements: [
      "M.S. or Ph.D. in Statistics, Data Science, or related field",
      "3+ years of experience in data science",
      "Strong foundation in statistics and causal inference",
      "Experience with A/B testing and experimentation",
      "Proficiency in Python and SQL",
      "Knowledge of machine learning techniques"
    ],
    responsibilities: [
      "Develop causal inference models for marketing attribution",
      "Design and analyze incrementality experiments",
      "Create advanced analytics solutions for marketing optimization",
      "Build predictive models for user behavior and conversion",
      "Implement privacy-preserving measurement techniques",
      "Collaborate with ML engineers on model deployment"
    ]
  },
  "open-application": {
    title: "Open Application",
    type: "Various Roles",
    location: "Remote",
    description: "We're seeking exceptional individuals across all disciplines who can help revolutionize marketing through AI innovation. Whether you're a designer, marketer, business strategist, or technologist, if you have a passion for transforming marketing with AI, we want to hear from you.",
    requirements: [
      "Exceptional expertise in your field (design, marketing, business, technology, etc.)",
      "Strong ability to grasp and apply new concepts",
      "Vision for how AI can transform marketing and business",
      "Track record of innovation and impact in your domain",
      "Excellent communication and collaboration skills",
      "Passion for continuous learning and adaptation"
    ],
    responsibilities: [
      "Shape the future of AI-driven marketing in your domain",
      "Collaborate with cross-functional teams of experts",
      "Bridge the gap between AI technology and practical applications",
      "Drive innovation in marketing technology",
      "Help define new standards in your area of expertise",
      "Contribute to our mission of transforming marketing through AI"
    ]
  }
}

const JobApplication = () => {
  const { positionId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const position = positions[positionId]

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    linkedin: '',
    experience: '',
    expertise: '',
    portfolio: '',
    coverLetter: '',
    resume: null,
    startDate: '',
    heardFrom: ''
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file && file.size > 10 * 1024 * 1024) { // 10MB limit
      toast({
        title: "File too large",
        description: "Please upload a file smaller than 10MB",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
      return
    }
    setFormData(prev => ({ ...prev, resume: file }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      // Create form data for email
      const formDataObj = new FormData()
      formDataObj.append('position', position.title)
      formDataObj.append('name', formData.name)
      formDataObj.append('email', formData.email)
      formDataObj.append('phone', formData.phone)
      formDataObj.append('linkedin', formData.linkedin)
      formDataObj.append('portfolio', formData.portfolio)
      formDataObj.append('experience', formData.experience)
      formDataObj.append('coverLetter', formData.coverLetter)
      formDataObj.append('startDate', formData.startDate)
      formDataObj.append('heardFrom', formData.heardFrom)
      if (formData.resume) {
        formDataObj.append('resume', formData.resume)
      }

      // Create email content
      const emailContent = `
Position: ${position.title}
Name: ${formData.name}
Email: ${formData.email}
Phone: ${formData.phone}
LinkedIn: ${formData.linkedin}
Area of Expertise: ${formData.expertise}
Portfolio/Work Samples: ${formData.portfolio}
Professional Experience: ${formData.experience}
Start Date: ${formData.startDate}
Source: ${formData.heardFrom}

Cover Letter:
${formData.coverLetter}
      `

      // Create mailto link with all information
      const mailtoLink = `mailto:yong.huang@sig.ai?subject=Job Application: ${position.title}&body=${encodeURIComponent(emailContent)}`
      
      // Open email client
      window.location.href = mailtoLink

      // Show success message
      toast({
        title: "Application ready to send!",
        description: "Your email client has been opened with the application details.",
        status: "success",
        duration: 5000,
        isClosable: true,
      })

      // Redirect after a delay
      const redirectTimer = setTimeout(() => {
        navigate('/careers')
      }, 5000)
      return () => clearTimeout(redirectTimer)
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error preparing your application. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  if (!position) {
    return (
      <Container maxW="4xl" py={16}>
        <Alert status="error">
          <AlertIcon />
          Position not found. Please check the URL and try again.
        </Alert>
        <Button
          leftIcon={<FaArrowLeft />}
          mt={4}
          onClick={() => navigate('/careers')}
        >
          Back to Careers
        </Button>
      </Container>
    )
  }

  return (
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="4xl">
          <Button
            leftIcon={<FaArrowLeft />}
            variant="ghost"
            mb={8}
            onClick={() => navigate('/careers')}
          >
            Back to Careers
          </Button>
          
          <Heading as="h1" size="2xl" mb={4}>
            {position.title}
          </Heading>
          <Text fontSize="lg" color="gray.600" mb={2}>
            {position.type} • {position.location}
          </Text>
          <Text fontSize="xl" mb={6}>
            {position.description}
          </Text>

          <VStack align="start" spacing={6} mb={8}>
            <Box>
              <Heading size="md" mb={4}>Requirements</Heading>
              <VStack align="start" spacing={2}>
                {position.requirements.map((req, index) => (
                  <Text key={index}>• {req}</Text>
                ))}
              </VStack>
            </Box>

            <Box>
              <Heading size="md" mb={4}>Responsibilities</Heading>
              <VStack align="start" spacing={2}>
                {position.responsibilities.map((resp, index) => (
                  <Text key={index}>• {resp}</Text>
                ))}
              </VStack>
            </Box>
          </VStack>
        </Container>
      </Box>

      <Container maxW="4xl" py={16}>
        <Box as="form" onSubmit={handleSubmit}>
          <VStack spacing={6} align="stretch">
            <Heading size="lg" mb={4}>Apply Now</Heading>

            {/* Personal Information */}
            <FormControl isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your full name"
              />
            </FormControl>

            <HStack spacing={6}>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Enter your phone number"
                />
              </FormControl>
            </HStack>

            <FormControl>
              <FormLabel>LinkedIn Profile</FormLabel>
              <Input
                name="linkedin"
                value={formData.linkedin}
                onChange={handleInputChange}
                placeholder="Enter your LinkedIn profile URL"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Portfolio/Work Samples</FormLabel>
              <Input
                name="portfolio"
                value={formData.portfolio}
                onChange={handleInputChange}
                placeholder="URL to your portfolio, GitHub, case studies, or work samples"
              />
              <FormHelperText>Share examples of your work that demonstrate your expertise (Optional)</FormHelperText>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Professional Experience</FormLabel>
              <Select
                name="experience"
                value={formData.experience}
                onChange={handleInputChange}
                placeholder="Select years of experience in your field"
              >
                <option value="0-2">0-2 years</option>
                <option value="3-5">3-5 years</option>
                <option value="5-10">5-10 years</option>
                <option value="10+">10+ years</option>
                <option value="expert">Industry Expert/Thought Leader</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Area of Expertise</FormLabel>
              <Select
                name="expertise"
                value={formData.expertise}
                onChange={handleInputChange}
                placeholder="Select your primary area of expertise"
              >
                <option value="design">Design & User Experience</option>
                <option value="marketing">Marketing & Growth</option>
                <option value="business">Business Development & Strategy</option>
                <option value="technology">Technology & Engineering</option>
                <option value="data">Data Science & Analytics</option>
                <option value="product">Product Management</option>
                <option value="other">Other Expertise</option>
              </Select>
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Cover Letter</FormLabel>
              <Textarea
                name="coverLetter"
                value={formData.coverLetter}
                onChange={handleInputChange}
                placeholder="Tell us about your vision for AI in marketing, how your expertise can contribute to our mission, and what makes you uniquely qualified to help transform the industry"
                minH="200px"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Resume/CV</FormLabel>
              <Input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                p={1}
              />
              <FormHelperText>PDF, DOC, or DOCX format, max 10MB</FormHelperText>
            </FormControl>

            <FormControl>
              <FormLabel>Earliest Start Date</FormLabel>
              <Input
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>How did you hear about us?</FormLabel>
              <Select
                name="heardFrom"
                value={formData.heardFrom}
                onChange={handleInputChange}
                placeholder="Select an option"
              >
                <option value="linkedin">LinkedIn</option>
                <option value="website">Company Website</option>
                <option value="referral">Employee Referral</option>
                <option value="jobboard">Job Board</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>

            <Box pt={6}>
              <Button
                type="submit"
                colorScheme="blue"
                size="lg"
                width="full"
              >
                Submit Application
              </Button>
            </Box>

            <Text fontSize="sm" color="gray.600" textAlign="center">
              By submitting this application, you agree to our{' '}
              <Link color="blue.500" href="/privacy">Privacy Policy</Link> and{' '}
              <Link color="blue.500" href="/terms">Terms of Service</Link>.
            </Text>
          </VStack>
        </Box>
      </Container>
    </Box>
  )
}

export default JobApplication