import { Box, Container, Heading, Text, SimpleGrid, VStack, Icon } from '@chakra-ui/react'
import { FaChartBar, FaRoute, FaClock, FaDatabase } from 'react-icons/fa'

const Solutions = () => {
  const solutions = [
    {
      icon: FaChartBar,
      id: 'marketing-roi',
      title: 'Marketing ROI & Incrementality',
      description: `Say goodbye to vanity metrics. Our platform focuses on incrementality—measuring the net-new 
        sales or leads you wouldn't have gotten otherwise. We use systematic experiments like ghost ads and 
        control groups to isolate true causality.`
    },
    {
      icon: FaRoute,
      id: 'attribution',
      title: 'Multi-Channel Attribution',
      description: `Break down silos with cross-channel visibility. We integrate data from paid social, search, 
        programmatic, CTV, offline channels, and CRM to map the entire consumer journey and optimize accordingly.`
    },
    {
      icon: FaClock,
      id: 'optimization',
      title: 'Real-Time Budget Optimization',
      description: `Our reinforcement learning agents continuously monitor performance and external signals, 
        dynamically adjusting campaign budgets, bids, and audience targeting to maximize ROI in real-time.`
    },
    {
      icon: FaDatabase,
      id: 'infrastructure',
      title: 'Data Infrastructure & Integration',
      description: `We build or augment your data stack with robust ETL pipelines, standardized schemas, and 
        automated refreshes. Privacy and compliance are built-in, ensuring GDPR/CCPA readiness.`
    }
  ]

  return (
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>Solutions</Heading>
            <Text fontSize="xl" color="gray.600">
              Discover our comprehensive suite of marketing solutions powered by AI, designed to 
              deliver measurable results in today's complex digital landscape.
            </Text>
          </Box>
        </Container>
      </Box>

      <Container maxW="8xl" py={16}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {solutions.map((solution, index) => (
            <Box
              key={index}
              id={solution.id}
              p={8}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
              transition="all 0.3s"
              scrollMarginTop="100px"
            >
              <VStack align="start" spacing={4}>
                <Icon as={solution.icon} w={8} h={8} color="blue.500" />
                <Heading size="md">{solution.title}</Heading>
                <Text color="gray.600">{solution.description}</Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default Solutions