import { Box, Container, Heading, Text, SimpleGrid, VStack, Button, Tag, Image, HStack, Icon, Link, List, ListItem, ListIcon } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FaDownload, FaArrowRight, FaChartLine, FaBrain, FaLock, FaCalendar, FaCheck, FaGlobe, FaRocket, FaUsers } from 'react-icons/fa'

const Careers = () => {
  const companyInfo = {
    mission: "Our mission is to revolutionize marketing through autonomous AI systems that maximize ROI while preserving user privacy.",
    values: [
      {
        title: "Innovation First",
        description: "We push the boundaries of AI and machine learning to solve complex marketing challenges.",
        icon: FaBrain
      },
      {
        title: "Privacy by Design",
        description: "We believe in building privacy-preserving systems from the ground up.",
        icon: FaLock
      },
      {
        title: "Global Impact",
        description: "We're building solutions that transform marketing worldwide.",
        icon: FaGlobe
      },
      {
        title: "Customer Success",
        description: "We measure our success through our customers' achievements.",
        icon: FaRocket
      }
    ],
    culture: [
      "Remote-first environment with flexible work hours",
      "Collaborative and inclusive team culture",
      "Continuous learning and development",
      "Regular team events and meetups",
      "Open communication and transparency"
    ]
  }

  const jobs = [
    {
      title: "Senior ML Engineer",
      type: "Full-time • Remote",
      department: "Engineering",
      level: "Senior",
      description: "Build and optimize multi-agent reinforcement learning systems for marketing automation.",
      responsibilities: [
        "Design and implement multi-agent reinforcement learning architectures",
        "Develop real-time bidding and budget optimization algorithms",
        "Create scalable ML pipelines for model training and deployment",
        "Optimize model performance and system efficiency",
        "Collaborate with data scientists to improve prediction accuracy",
        "Implement privacy-preserving ML techniques"
      ],
      requirements: [
        "Ph.D. or M.S. in Computer Science, Machine Learning, or related field",
        "5+ years of experience in ML engineering",
        "Deep understanding of RL algorithms (DQN, PPO, DDPG)",
        "Experience with multi-agent systems",
        "Proficiency in Python and ML frameworks",
        "Strong system design and architecture skills"
      ],
      preferred: [
        "Experience with marketing technology",
        "Publications in top ML conferences",
        "Contributions to open-source ML projects"
      ]
    },
    {
      title: "Data Scientist",
      type: "Full-time • Remote",
      department: "Data Science",
      level: "Mid-Senior",
      description: "Develop and implement advanced analytics solutions for marketing attribution and optimization.",
      responsibilities: [
        "Develop causal inference models for marketing attribution",
        "Design and analyze incrementality experiments",
        "Create advanced analytics solutions for marketing optimization",
        "Build predictive models for user behavior and conversion",
        "Implement privacy-preserving measurement techniques",
        "Collaborate with ML engineers on model deployment"
      ],
      requirements: [
        "M.S. or Ph.D. in Statistics, Data Science, or related field",
        "3+ years of experience in data science",
        "Strong foundation in statistics and causal inference",
        "Experience with A/B testing and experimentation",
        "Proficiency in Python and SQL",
        "Knowledge of machine learning techniques"
      ],
      preferred: [
        "Experience with marketing analytics",
        "Background in econometrics",
        "Publications or research experience"
      ]
    }
  ]

  const benefits = [
    "Competitive salary and equity",
    "Comprehensive health, dental, and vision coverage",
    "Flexible PTO policy",
    "Remote-first work environment",
    "Learning and development budget",
    "Latest hardware and tools",
    "401(k) matching",
    "Home office setup allowance",
    "Wellness programs"
  ]

  return (
    <Box>
      {/* Hero Section */}
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>Open Positions</Heading>
            <Text fontSize="xl" color="gray.600" mb={8}>
              Join us in revolutionizing marketing through AI. We're looking for talented individuals who share our passion for innovation and privacy-first technology.
            </Text>
          </Box>
        </Container>
      </Box>

      {/* Job Listings */}
      <Container maxW="8xl" py={16}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
          {jobs.map((job, index) => (
            <Box
              key={index}
              p={8}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ transform: "translateY(-4px)", shadow: "lg" }}
              transition="all 0.3s"
            >
              <VStack align="start" spacing={6}>
                <HStack>
                  <Tag size="lg" colorScheme="blue">{job.department}</Tag>
                  <Tag size="lg" colorScheme="purple">{job.level}</Tag>
                </HStack>
                <Heading size="lg">{job.title}</Heading>
                <Text color="gray.600" fontSize="lg">{job.type}</Text>
                <Text>{job.description}</Text>
                
                <Box w="full">
                  <Heading size="sm" mb={3}>Key Responsibilities:</Heading>
                  <List spacing={2}>
                    {job.responsibilities.map((resp, i) => (
                      <ListItem key={i}>
                        <HStack align="start">
                          <ListIcon as={FaCheck} color="green.500" mt={1} />
                          <Text>{resp}</Text>
                        </HStack>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box w="full">
                  <Heading size="sm" mb={3}>Requirements:</Heading>
                  <List spacing={2}>
                    {job.requirements.map((req, i) => (
                      <ListItem key={i}>
                        <HStack align="start">
                          <ListIcon as={FaCheck} color="green.500" mt={1} />
                          <Text>{req}</Text>
                        </HStack>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Button
                  as={RouterLink}
                  to={`/careers/${job.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}`}
                  colorScheme="blue"
                  size="lg"
                  width="full"
                >
                  Apply Now
                </Button>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>

      {/* Values Section */}
      <Box bg="gray.50" py={16}>
        <Container maxW="8xl">
          <Heading as="h2" size="xl" mb={12} textAlign="center">Our Values</Heading>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {companyInfo.values.map((value, index) => (
              <Box
                key={index}
                p={8}
                bg="white"
                rounded="xl"
                shadow="base"
                _hover={{ transform: "translateY(-4px)", shadow: "lg" }}
                transition="all 0.3s"
              >
                <VStack align="start" spacing={4}>
                  <Icon as={value.icon} w={8} h={8} color="blue.500" />
                  <Heading size="md">{value.title}</Heading>
                  <Text color="gray.600">{value.description}</Text>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Benefits Section */}
      <Container maxW="8xl" py={16}>
        <Heading as="h2" size="xl" mb={12} textAlign="center">Benefits & Perks</Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {benefits.map((benefit, index) => (
            <HStack
              key={index}
              p={4}
              bg="white"
              rounded="lg"
              shadow="base"
              _hover={{ transform: "translateY(-2px)", shadow: "md" }}
              transition="all 0.3s"
            >
              <Icon as={FaCheck} color="green.500" />
              <Text>{benefit}</Text>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>

      {/* Culture Section */}
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Heading as="h2" size="xl" mb={12} textAlign="center">Our Culture</Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            <Box>
              <Image
                src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?w=800"
                alt="Team collaboration"
                rounded="xl"
                shadow="lg"
              />
            </Box>
            <VStack align="start" spacing={6} justify="center">
              <List spacing={4}>
                {companyInfo.culture.map((item, index) => (
                  <ListItem key={index}>
                    <HStack>
                      <ListIcon as={FaCheck} color="green.500" />
                      <Text>{item}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </VStack>
          </SimpleGrid>
        </Container>
      </Box>

      {/* CTA Section */}
      <Container maxW="8xl" py={16}>
        <Box textAlign="center">
          <Heading as="h2" size="xl" mb={6}>Don't See the Right Role?</Heading>
          <Text fontSize="xl" color="gray.600" mb={8}>
            We're always looking for talented individuals who share our vision and values.
          </Text>
          <Button
            as={RouterLink}
            to="/careers/open-application"
            size="lg"
            colorScheme="blue"
            leftIcon={<Icon as={FaUsers} />}
            width={{ base: "full", md: "auto" }}
          >
            Submit Open Application
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default Careers
