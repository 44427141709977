import { Box, Container, Heading, Text, SimpleGrid, VStack, Button, Tag, Image, HStack, Icon, Link, Divider, useClipboard, useToast, useDisclosure } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FaDownload, FaArrowRight, FaChartLine, FaBrain, FaLock, FaCalendar, FaPhone, FaCopy, FaCheck } from 'react-icons/fa'
import { SiGoogle } from 'react-icons/si'
import { useState } from 'react'
import ResourceRegistrationWall from '../components/ResourceRegistrationWall'

const Resources = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedResource, setSelectedResource] = useState({
    title: '',
    url: ''
  })
  const blogPosts = [
    {
      title: 'The End of Third-Party Cookies: A New Era of Marketing Measurement',
      description: `As Google phases out third-party cookies, we explore how businesses can adapt using 
        first-party data, probabilistic matching, and advanced incrementality measurement techniques. 
        Learn how our clients achieved 40% better attribution accuracy using cookie-less methods.`,
      category: 'Analytics',
      date: 'Feb 2025',
      readTime: '8 min read',
      image: 'https://images.unsplash.com/photo-1614064641938-3bbee52942c7?w=800&auto=format&fit=crop&q=80',
      author: {
        name: 'CJ',
        role: 'Principal Data Scientist'
      }
    },
    {
      title: 'Multi-Agent Reinforcement Learning: The Future of Marketing Automation',
      description: `Deep dive into how our multi-agent RL system coordinates bidding, budget allocation, 
        and creative optimization across channels. Case study featuring a D2C brand that achieved 65% 
        improvement in ROAS through automated, real-time optimization.`,
      category: 'AI & ML',
      date: 'Jan 2025',
      readTime: '12 min read',
      image: 'https://images.unsplash.com/photo-1620712943543-bcc4688e7485?w=800&auto=format&fit=crop&q=80',
      author: {
        name: 'Yong Huang',
        role: 'Founder & CEO'
      }
    },
    {
      title: 'Incrementality Testing at Scale: Moving Beyond A/B Tests',
      description: `How modern incrementality measurement differs from traditional A/B testing. 
        We cover ghost ads, PSA tests, and geo-experiments, with real examples from our insurance 
        and e-commerce clients showing 30%+ improvement in budget efficiency.`,
      category: 'Strategy',
      date: 'Dec 2024',
      readTime: '10 min read',
      image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?w=800&auto=format&fit=crop&q=80',
      author: {
        name: 'James Wang',
        role: 'Head of Engineering'
      }
    }
  ]

  const whitepapers = [
    {
      title: 'The Complete Guide to Marketing Incrementality Measurement',
      description: `A comprehensive guide covering:
        • Different incrementality testing methodologies
        • Setting up ghost ads and PSA tests
        • Statistical significance in incrementality measurement
        • Case studies from e-commerce and insurance
        • Implementation checklist and best practices`,
      type: 'White Paper',
      icon: FaChartLine,
      downloadFormat: 'PDF (446 KB)',
      pages: 21,
      preview: '/whitepapers/incrementality-guide-preview.pdf',
      download: '/whitepapers/incrementality-guide.pdf'
    },
    {
      title: 'Multi-Agent Reinforcement Learning for Marketing Optimization',
      description: `Technical white paper exploring:
        • MARL architecture and agent coordination
        • Reward function design for marketing objectives
        • Real-time bidding and budget optimization
        • Handling delayed conversion signals
        • System deployment and monitoring`,
      type: 'Technical Paper',
      icon: FaBrain,
      downloadFormat: 'PDF (403 KB)',
      pages: 19,
      preview: '/whitepapers/marl-technical-paper-preview.pdf',
      download: '/whitepapers/marl-technical-paper.pdf'
    },
    {
      title: 'Privacy-First Marketing Analytics Playbook',
      description: `Strategic guide covering:
        • First-party data collection and activation
        • Privacy-preserving measurement techniques
        • GDPR and CCPA compliance in analytics
        • Cookie-less tracking alternatives
        • Future-proofing your marketing stack`,
      type: 'Strategy Guide',
      icon: FaLock,
      downloadFormat: 'PDF (278 KB)',
      pages: 11,
      preview: '/whitepapers/privacy-first-guide-preview.pdf',
      download: '/whitepapers/privacy-first-guide.pdf'
    }
  ]

  const events = [
    {
      title: 'AI in Marketing Summit 2025',
      date: 'October 7, 2025',
      time: '12:00 PM - 4:00 PM PT',
      type: 'Virtual Conference',
      platform: 'Google Meet',
      platformIcon: SiGoogle,
      platformColor: 'red',
      meetingLink: 'https://meet.google.com/jgg-cocs-rot',
      dialIn: {
        number: '+1 904-800-7048',
        pin: '506 505 312',
        moreNumbers: 'https://tel.meet/jgg-cocs-rot?pin=5577266338357'
      },
      description: 'Join our CEO and industry experts for an in-depth exploration of AI-driven marketing innovations and real-world applications.',
      agenda: [
        '12:00 PM PT - Welcome & Introduction',
        '12:15 PM PT - Keynote: The Future of AI in Marketing',
        '1:00 PM PT - Panel: Real-world Applications & Case Studies',
        '2:00 PM PT - Technical Deep Dive: MARL Systems',
        '3:00 PM PT - Privacy-First Marketing Analytics',
        '3:45 PM PT - Q&A and Closing Remarks'
      ]
    },
    {
      title: 'Incrementality Measurement Workshop',
      date: 'May 12, 2025',
      time: '12:00 PM - 4:00 PM PT',
      type: 'Interactive Workshop',
      platform: 'Google Meet',
      platformIcon: SiGoogle,
      platformColor: 'red',
      meetingLink: 'https://meet.google.com/nnz-vwbu-qck',
      dialIn: {
        number: '+1 252-802-6149',
        pin: '783 346 306',
        moreNumbers: 'https://tel.meet/nnz-vwbu-qck?pin=9585101085546'
      },
      description: 'A comprehensive workshop on advanced incrementality measurement techniques, featuring hands-on implementation guidance and real-world case studies.',
      agenda: [
        '12:00 PM PT - Workshop Introduction & Overview',
        '12:30 PM PT - Incrementality Fundamentals & Best Practices',
        '1:30 PM PT - Advanced Measurement Techniques',
        '2:30 PM PT - Coffee Break',
        '2:45 PM PT - Live Implementation Demo',
        '3:15 PM PT - Interactive Practice Session',
        '3:45 PM PT - Q&A and Wrap-up'
      ]
    }
  ]

  return (
    <>
    <Box>
      <Box bg="blue.50" py={16}>
        <Container maxW="8xl">
          <Box maxW="3xl" mx="auto" textAlign="center">
            <Heading as="h1" size="2xl" mb={6}>Resources</Heading>
            <Text fontSize="xl" color="gray.600">
              Explore our latest insights, guides, and events to stay ahead in AI-driven marketing.
            </Text>
          </Box>
        </Container>
      </Box>

      {/* Blog Posts Section */}
      <Container maxW="8xl" py={16} id="blog">
        <Heading size="xl" mb={8}>Latest Insights</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {blogPosts.map((post, index) => (
            <Box
              key={index}
              bg="white"
              rounded="xl"
              shadow="base"
              overflow="hidden"
              _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
              transition="all 0.3s"
            >
              <Image
                src={post.image}
                alt={post.title}
                w="full"
                h="200px"
                objectFit="cover"
              />
              <Box p={6}>
                <VStack align="start" spacing={4}>
                  <HStack spacing={4}>
                    <Tag colorScheme="blue">{post.category}</Tag>
                    <Text fontSize="sm" color="gray.500">{post.readTime}</Text>
                  </HStack>
                  <Heading size="md">{post.title}</Heading>
                  <Text color="gray.600" noOfLines={3}>{post.description}</Text>
                  <Box pt={4}>
                    <Text fontSize="sm" fontWeight="medium">{post.author.name}</Text>
                    <Text fontSize="sm" color="gray.500">{post.author.role}</Text>
                    <Text fontSize="sm" color="gray.500" mt={2}>{post.date}</Text>
                  </Box>
                  <Button
                    as={RouterLink}
                    to={"/blog/" + post.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}
                    rightIcon={<Icon as={FaArrowRight} />}
                    variant="link"
                    colorScheme="blue"
                    mt={2}
                  >
                    Read More
                  </Button>
                </VStack>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Container>

      {/* White Papers Section */}
      <Box bg="gray.50" py={16} id="whitepapers">
        <Container maxW="8xl">
          <Heading size="xl" mb={8}>White Papers & Guides</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            {whitepapers.map((paper, index) => (
              <Box
                key={index}
                p={8}
                bg="white"
                rounded="xl"
                shadow="base"
                _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
                transition="all 0.3s"
              >
                <VStack align="start" spacing={6}>
                  <Icon as={paper.icon} w={10} h={10} color="blue.500" />
                  <Tag colorScheme="purple" size="lg">{paper.type}</Tag>
                  <Heading size="md">{paper.title}</Heading>
                  <Text color="gray.600" whiteSpace="pre-line">{paper.description}</Text>
                  <Box w="full" pt={4}>
                    <HStack justify="space-between" w="full" pb={4}>
                      <Text fontSize="sm" color="gray.500">{paper.pages} pages</Text>
                      <Text fontSize="sm" color="gray.500">{paper.downloadFormat}</Text>
                    </HStack>
                    <HStack spacing={4}>
                      <Button
                        as="a"
                        href={paper.preview}
                        target="_blank"
                        variant="outline"
                        colorScheme="blue"
                        size="sm"
                      >
                        Preview
                      </Button>
                      <Button
                        onClick={() => {
                          setSelectedResource({
                            title: paper.title,
                            url: paper.download
                          });
                          onOpen();
                        }}
                        leftIcon={<Icon as={FaDownload} />}
                        colorScheme="blue"
                        size="sm"
                      >
                        Download
                      </Button>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Events Section */}
      <Container maxW="8xl" py={16} id="events">
        <Heading size="xl" mb={8}>Upcoming Events</Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {events.map((event, index) => (
            <Box
              key={index}
              p={6}
              bg="white"
              rounded="xl"
              shadow="base"
              _hover={{ transform: 'translateY(-4px)', shadow: 'lg' }}
              transition="all 0.3s"
            >
              <VStack align="start" spacing={4} w="full">
                <HStack spacing={2}>
                  <Tag colorScheme="green">{event.type}</Tag>
                  <Tag colorScheme={event.platformColor}>
                    <HStack spacing={2}>
                      <Icon as={event.platformIcon} />
                      <Text>{event.platform}</Text>
                    </HStack>
                  </Tag>
                </HStack>
                <Heading size="md">{event.title}</Heading>
                <VStack align="start" spacing={2}>
                  <HStack>
                    <Icon as={FaCalendar} color="blue.500" />
                    <Text fontWeight="medium" color="blue.600">{event.date}</Text>
                  </HStack>
                  <Text color="blue.600" fontSize="sm">{event.time}</Text>
                </VStack>
                <Text color="gray.600">{event.description}</Text>
                <Box bg="gray.50" p={4} rounded="md" w="full">
                  <Text fontWeight="bold" mb={2}>Agenda:</Text>
                  <VStack align="start" spacing={1}>
                    {event.agenda.map((item, i) => (
                      <Text key={i} fontSize="sm" color="gray.600">{item}</Text>
                    ))}
                  </VStack>
                </Box>
                <Box bg="blue.50" p={4} rounded="md" w="full">
                  <VStack align="start" spacing={3}>
                    <Text fontWeight="bold" color="blue.700">Meeting Details:</Text>
                    <VStack align="start" spacing={2} w="full">
                      <Text fontSize="sm" color="gray.700">Google Meet Link:</Text>
                      <HStack w="full" bg="white" p={2} rounded="md">
                        <Text fontSize="sm" color="blue.600" flex="1" noOfLines={1}>
                          {event.meetingLink}
                        </Text>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => {
                            navigator.clipboard.writeText(event.meetingLink);
                          }}
                          leftIcon={<Icon as={FaCopy} />}
                        >
                          Copy
                        </Button>
                      </HStack>
                      {event.dialIn && (
                        <>
                          <Text fontSize="sm" color="gray.700" mt={2}>Dial-in:</Text>
                          <HStack w="full" bg="white" p={2} rounded="md">
                            <Icon as={FaPhone} color="gray.500" />
                            <Text fontSize="sm" flex="1">
                              {event.dialIn.number}
                            </Text>
                            <Button
                              size="sm"
                              variant="ghost"
                              onClick={() => {
                                navigator.clipboard.writeText(event.dialIn.number);
                              }}
                              leftIcon={<Icon as={FaCopy} />}
                            >
                              Copy
                            </Button>
                          </HStack>
                          <HStack w="full" bg="white" p={2} rounded="md">
                            <Text fontSize="sm" color="gray.600">PIN: {event.dialIn.pin}</Text>
                            <Button
                              size="sm"
                              variant="ghost"
                              onClick={() => {
                                navigator.clipboard.writeText(event.dialIn.pin);
                              }}
                              leftIcon={<Icon as={FaCopy} />}
                            >
                              Copy
                            </Button>
                          </HStack>
                          <Link
                            href={event.dialIn.moreNumbers}
                            target="_blank"
                            fontSize="sm"
                            color="blue.500"
                          >
                            More phone numbers
                          </Link>
                        </>
                      )}
                    </VStack>
                  </VStack>
                </Box>
                <HStack spacing={4} pt={2}>
                  <Button
                    as={Link}
                    href={event.meetingLink}
                    target="_blank"
                    colorScheme={event.platformColor}
                    leftIcon={<Icon as={event.platformIcon} />}
                  >
                    Join Google Meet
                  </Button>
                  <Button
                    as={Link}
                    href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.title)}&details=${encodeURIComponent(
                      event.description + 
                      '\n\nGoogle Meet Link: ' + event.meetingLink + 
                      (event.dialIn ? '\n\nDial-in: ' + event.dialIn.number + '\nPIN: ' + event.dialIn.pin : '') +
                      '\n\nAgenda:\n' + event.agenda.join('\n')
                    )}&dates=${event.date.replace(/[^0-9]/g, '')}T190000Z/${event.date.replace(/[^0-9]/g, '')}T230000Z`}
                    target="_blank"
                    colorScheme="blue"
                    variant="outline"
                    leftIcon={<Icon as={FaCalendar} />}
                  >
                    Add to Calendar
                  </Button>
                </HStack>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
    
    <ResourceRegistrationWall
      isOpen={isOpen}
      onClose={onClose}
      resourceTitle={selectedResource.title}
      resourceUrl={selectedResource.url}
    />
  </>
  )
}

export default Resources