// SEO utility functions

/**
 * Updates the canonical URL and other SEO meta tags based on the current route
 * @param {string} path - The current route path
 */
export const updateCanonicalUrl = (path) => {
  // Base URL for the site
  const baseUrl = 'https://www.sig.ai';
  
  // Get the canonical link element
  let canonicalLink = document.querySelector('link[rel="canonical"]');
  
  // If it doesn't exist, create it
  if (!canonicalLink) {
    canonicalLink = document.createElement('link');
    canonicalLink.rel = 'canonical';
    document.head.appendChild(canonicalLink);
  }
  
  // Set the canonical URL based on the current path
  // For the homepage, use the base URL
  if (path === '/') {
    canonicalLink.href = baseUrl;
  } else {
    // For other pages, append the path to the base URL
    canonicalLink.href = `${baseUrl}${path}`;
  }
  
  // Update Open Graph and Twitter URLs
  const ogUrlMeta = document.querySelector('meta[property="og:url"]');
  const twitterUrlMeta = document.querySelector('meta[name="twitter:url"]');
  
  if (ogUrlMeta) {
    ogUrlMeta.content = canonicalLink.href;
  }
  
  if (twitterUrlMeta) {
    twitterUrlMeta.content = canonicalLink.href;
  }
  
  console.log(`Updated canonical URL to: ${canonicalLink.href}`);
};

/**
 * Updates page title and meta description based on the current route
 * @param {string} path - The current route path
 * @param {string} title - The page title
 * @param {string} description - The page description
 */
export const updateMetaTags = (path, title, description) => {
  // Validate and optimize title length (50-60 characters is ideal for SEO)
  const optimizedTitle = validateTitle(title);
  
  // Validate and optimize description length (150-160 characters is ideal for SEO)
  const optimizedDescription = validateDescription(description);
  
  // Update page title
  document.title = optimizedTitle;
  
  // Update meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.content = optimizedDescription;
  }
  
  // Update Open Graph title and description
  const ogTitle = document.querySelector('meta[property="og:title"]');
  const ogDescription = document.querySelector('meta[property="og:description"]');
  
  if (ogTitle) {
    ogTitle.content = optimizedTitle;
  }
  
  if (ogDescription) {
    ogDescription.content = optimizedDescription;
  }
  
  // Update Twitter title and description
  const twitterTitle = document.querySelector('meta[name="twitter:title"]');
  const twitterDescription = document.querySelector('meta[name="twitter:description"]');
  
  if (twitterTitle) {
    twitterTitle.content = optimizedTitle;
  }
  
  if (twitterDescription) {
    twitterDescription.content = optimizedDescription;
  }
  
  console.log(`Updated meta tags for: ${path}`);
  console.log(`Title (${optimizedTitle.length} chars): ${optimizedTitle}`);
  console.log(`Description (${optimizedDescription.length} chars): ${optimizedDescription}`);
};

/**
 * Validates and optimizes title length for SEO
 * @param {string} title - The page title
 * @returns {string} - Optimized title
 */
const validateTitle = (title) => {
  if (!title) {
    console.warn('Empty title provided');
    return 'sig.ai - AI-Powered Marketing Optimization';
  }
  
  // Ideal title length is 50-60 characters
  if (title.length > 70) {
    console.warn(`Title is too long (${title.length} chars): "${title}"`);
    // Truncate to 67 characters and add ellipsis
    return title.substring(0, 67) + '...';
  }
  
  if (title.length < 20) {
    console.warn(`Title is too short (${title.length} chars): "${title}"`);
  }
  
  return title;
};

/**
 * Validates and optimizes description length for SEO
 * @param {string} description - The page description
 * @returns {string} - Optimized description
 */
const validateDescription = (description) => {
  if (!description) {
    console.warn('Empty description provided');
    return 'Maximize marketing ROI with sig.ai\'s AI-powered optimization platform.';
  }
  
  // Ideal description length is 150-160 characters
  if (description.length > 160) {
    console.warn(`Description is too long (${description.length} chars): "${description}"`);
    // Truncate to 157 characters and add ellipsis
    return description.substring(0, 157) + '...';
  }
  
  if (description.length < 70) {
    console.warn(`Description is too short (${description.length} chars): "${description}"`);
  }
  
  return description;
};